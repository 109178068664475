import { Component, OnChanges, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as map from 'lodash/map';
import { UnitConvertPipe } from './../../../../pipes/unit-convert.pipe';
import { ThresholdService } from './../../../../services/threshold.service';
import { EnquiriesService } from './../../../../services/enquiries.service';
import { LoaderService } from './../../../../services/loader.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-thresholds-item',
  templateUrl: './thresholds-item.component.html',
  styleUrls: ['./thresholds-item.component.scss']
})
export class ThresholdsItemComponent implements AfterViewInit, OnChanges {
    @Input() type: string = 'rain';
    @Input() thresholdsData: any;
    @Input() unitSelected: string = 'mm';
    ischangedunit: boolean = false;
    isChanged: boolean = false;
    isUnitChanged: boolean = false;
    units: any = ['', ''];
    order: string = 'ASC';
    orderItems: any = [
        {
            'slug': 'below',
            'name': 'Below',
            'active': 0,
        },
        {
            'slug': 'above',
            'name': 'Above',
            'active': 0,
        }
    ];
    counterModMinMax: number[] = [0, 250];
    counterModStep: number = 5;
    counterMod: number = 0;
    counterSevMinMax: number[] = [0, 300];
    counterSevStep: number = 5;
    counterSev: number = 10;
    weatherSymbol:any;
    selectedOrderItem:string;
    selectedUnit:string;
    lastclicked = '';
    inputMaxLength: number = 6;
    inputMaxDecimal: number = 2;
    tempHeldInputValue: string;
    @Input() notify: Subject<boolean>;

    
    @Output() onUpdateThresholds: EventEmitter<any> = new EventEmitter();
    @Output() onUnitConvert: EventEmitter<any> = new EventEmitter();
    @Output() onThresholdsChangeMessage: EventEmitter<any> = new EventEmitter();

    constructor(
        private threshService :ThresholdService,
        private enquiriesService: EnquiriesService,
        private loader:LoaderService,
        private unitConvertPipe: UnitConvertPipe,
        private userserv:UserService
    ) { }

    ngOnInit(){
        this.notify.subscribe(v => { 
         this.setpreviousvalue()
           console.log("I am from child");
        });
     }
    ngOnChanges(): void {
        this.userserv.setcurSevnadMod();
    }

    setpreviousvalue(): void{
        
        if ( this.lastclicked=="lessmod") {
            this.counterMod = this.threshService.increaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        }
        if ( this.lastclicked == 'moremod') {
            this.counterMod = this.threshService.decreaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId, this.thresholdsData.weatherName);
        }    
        if ( this.lastclicked=="lesssev") {
            this.counterSev = this.threshService.increaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        }
        if ( this.lastclicked == 'moresev') {
            this.counterSev = this.threshService.decreaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId, this.thresholdsData.weatherName);
        } 
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.showSelectedOrderItme();
            this.counterMod = this.thresholdsData.alert;
            this.counterSev = this.thresholdsData.severe;
            this.units = [];
            for ( let [key, value] of Object.entries(this.thresholdsData.unitData) ) {
                this.units.push(value['name']);
            }
            this.unitSelected = this.units[0];
            this.unitSelected = this.thresholdsData.unit;
            this.weatherSymbol = this.thresholdsData.symbol;
            this.selectedUnit = this.unitSelected;
        }, 80);
       
    }

    onClickLessMod(){
        let updatedValue = this.threshService.decreaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId, this.thresholdsData.weatherName);
        this.userserv.setcurSevnadMod();
        this.counterMod = Number(updatedValue);
        this.validateChanged();
    }

    onClickMoreMod(){
        let updatedValue = this.threshService.increaseMinData(this.counterMod, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId);
        this.userserv.setcurSevnadMod();
        this.counterMod =Number(updatedValue);
        this.validateChanged();
    }

    onClickLessSev(){
        let updatedValue = this.threshService.decreaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType, this.thresholdsData.WeatherTypeId, this.thresholdsData.weatherName);
        this.userserv.setcurSevnadMod();
        this.counterSev = Number(updatedValue);
        this.validateChanged();
    }

    onClickMoreSev(){
        let updatedValue = this.threshService.increaseMinData(this.counterSev, this.thresholdsData.unit, this.thresholdsData.weatherVarType,this.thresholdsData.WeatherTypeId);
        this.userserv.setcurSevnadMod();
        this.counterSev = Number(updatedValue);
        this.validateChanged();
    }

    onOrderClick(name) {
        this.userserv.setcurSevnadMod();
        map(this.orderItems, item => {
            item.active = item.slug === name
        })
        let moderate = this.counterMod;
        this.counterMod = this.counterSev;
        this.counterSev = moderate;
        this.selectedOrderItem = name;
        this.validateChanged();
    }
    
    onUnitsClick() {
        const newUnits = [...this.units]
        newUnits.splice(newUnits.indexOf(this.unitSelected), 1)
        this.thresholdsData.unit = this.unitSelected = newUnits[0];
        let thresholdsArr = [this.counterMod, this.counterSev];
        let moderate = this.counterMod;
        let severe   = this.counterSev;
        let value: any = this.unitConvertPipe.convert(thresholdsArr, this.thresholdsData.weatherVarType, this.unitSelected, 'report', this.thresholdsData.WeatherTypeId, moderate, severe, this.selectedOrderItem);
        this.counterMod = Number(value[0]);
        this.counterSev = Number(value[1]);
        const thresholdUnit = this.thresholdsData.unitData[0].name;
        if (this.unitSelected === thresholdUnit) {
            //this.ischangedunit = false;
            this.isUnitChanged = false;
            this.validateChanged()
        } else {
            this.isUnitChanged = true;
            this.ischangedunit = true;
            this.isChanged = false;
        }
        // LOGIC TO CHANGE MIN/MAX AND STEP BASED ON UNITS SELECTED
    }

    showUpdateButton() {
        this.isChanged = true;
        this.isUnitChanged = false;
    }

    updateUnitButtonClicked() {
        this.updateButtonClicked();
    }

    updateButtonClicked() {
        this.counterMod = this.counterMod ?? 0;
        this.counterSev = this.counterSev ?? 0;
        if (this.counterMod == this.counterSev) {
            this.onUpdateThresholds.emit('sameval');
            return true;
        }
        if (this.selectedOrderItem === "above" && this.counterMod > this.counterSev) {
            this.onUpdateThresholds.emit('lessval');
            return true;
        }
        if (this.selectedOrderItem === "below" && this.counterMod < this.counterSev) {
            this.onUpdateThresholds.emit('moreval');
            return true;
        }
        let vartypedata = [];
        delete this.thresholdsData.allthresold[this.thresholdsData.weatherVarTypeId];
        for ( let [key, value] of Object.entries(this.thresholdsData.allthresold) ) {
            if(this.ischangedunit){
                let thresholdsArr = [value['moderate'], value['severe']];
                let moderate = value['moderate'];
                let severe   = value['severe'];
                let value1:any = this.unitConvertPipe.convert(thresholdsArr, value['type'], this.unitSelected, 'report', this.thresholdsData.WeatherTypeId, moderate, severe);
                vartypedata[key] = {'moderate':value1[0],'severe':value1[1]};
            }
        } 
        // update values
        this.loader.display(true);
        let body = JSON.stringify({
            "username": this.thresholdsData.username,
            "eventId": this.thresholdsData.eventId,
            "weatherType": this.thresholdsData.WeatherTypeId,
            "unit": this.unitSelected,
            "weatherVarType": this.thresholdsData.weatherVarTypeId,
            "lowerMin": this.counterMod,
            "lowerMax": this.counterSev,
            "orderItem": this.selectedOrderItem,
            "T1": this.counterMod,
            "T2": this.counterSev,
            "otherThreshold": vartypedata
        });
        
        this.enquiriesService.updateThresholds(body).
        subscribe(
            (data)=> {
                this.ischangedunit=false;
                this.userserv.setcurSevnadMod();
                this.loader.display(false);
                let successData = JSON.parse(JSON.stringify(data));
                successData.T1 = this.counterMod;
                successData.T2 = this.counterSev; 
                successData.selectedOrderItem = this.selectedOrderItem;
                this.onUpdateThresholds.emit(successData);
                      },
            (error)=> {
                    this.loader.display(false);
                    }          
                  );
        setTimeout(() => {
            this.isChanged = false;
        }, 300)
    }

    showSelectedOrderItme() {
        this.selectedOrderItem = this.thresholdsData.orderItem;
        for ( let i=0;i<2;i++ ) {
            if ( this.orderItems[i].slug == this.selectedOrderItem ) {
                this.orderItems[i].active = 1;
                break;
            }
        }
    }

    validateInput(event: KeyboardEvent): void {
        const inputChar = String.fromCharCode(event.charCode);
        const inputElement = event.target as HTMLInputElement;
        const currentValue = inputElement.value;

        let regex: RegExp;
        regex = this.type === "temperature" ? /^[0-9.-]$/ : /^[0-9.]$/; 

        if (!regex.test(inputChar) || (inputChar === '.' && currentValue.includes('.'))) {
            event.preventDefault();
        }
        if (this.type === "temperature" && inputChar === '-') {
            inputElement.setAttribute('type', 'text');
            const cursorPosition = inputElement.selectionStart;
            if (currentValue.includes('-') || cursorPosition > 0) {
                event.preventDefault();
                return;
            }
        }
        this.userserv.setcurSevnadMod();
    }
 
    validateChanged(event: KeyboardEvent | null = null): void {
        const thresholdModVal: number = Number(this.thresholdsData.alert);
        const counterModVal: number = Number(this.counterMod);
        const thresholdSevVal: number = Number(this.thresholdsData.severe);
        const counterSevVal: number = Number(this.counterSev);
        const thresholdOrderItem : string = this.thresholdsData.orderItem;
        const selectedOrderItem : string = this.selectedOrderItem;
        const thresholdUnit: string = this.thresholdsData.unit;
        const selectedUnit: string = this.selectedUnit;
        if (thresholdModVal === counterModVal && thresholdSevVal === counterSevVal && thresholdOrderItem === selectedOrderItem && thresholdUnit === selectedUnit) {
            this.isChanged = false;
        } else {
            this.showUpdateButton();
        }
    }

    enforceMaxLength(event: any): void {
        const inputElement = event.target as HTMLInputElement;
        let value = event.target.value;
        let maxLen = value[0] === '-' ? this.inputMaxLength + 1 : this.inputMaxLength;
        if (value.includes('.')) maxLen += 1;
        if (value.length > maxLen ) {
            event.target.value = value.slice(0, maxLen);
        }
        const decimalIndex = value.indexOf('.');
        if (decimalIndex !== -1) {
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > this.inputMaxDecimal) {
                event.target.value = value.slice(0, decimalIndex + this.inputMaxDecimal + 1);
            }
        }
        const inputEvent = new Event('input', { bubbles: true });
        event.target.dispatchEvent(inputEvent);
    }

}
