import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup } from "@angular/forms";

import axios from 'axios';
import Litepicker from '../../../litepicker2';
import '../../../litepicker2/plugins/mobilefriendly';
import * as moment from 'moment';
declare var google: any;
import { MapsAPILoader,  } from '@agm/core';
import { LoaderService } from '../../../services/loader.service';
import { NewEnquiryService } from './../../../services/newenquiry.service';
import Fuse from 'fuse.js';
import { map } from 'd3';


@Component({
  selector: 'app-newreport-with-insights-widget',
  templateUrl: './newreport-with-insights-widget.component.html',
  styleUrls: ['./newreport-with-insights-widget.component.scss']
})
export class NewreportWidgetWithInsightsComponent implements OnInit, AfterViewInit {
  newReportWidget: FormGroup;
  startDate: string = 'startDate';
  placeholderStart: string = 'Add start date';
  placeholderEnd: string = 'Add a end date';
  endDate: string = 'endDate';
  locationValue: string = '';
  locationResults: any = false;
  locationSelected: any = false;
  searchState: any = false;
  datepickerInput: any = false;
  nextcount = 0;
  regionListArr: any[] = [];
  regionListWithGeojsonArr: any[] = [];
  autocompleteService: any;
  placesService: any;
  mapLocation: [number, number];
  mapGeojson: any;
  mapPin: boolean;
  mapPrefill: boolean = false;
  public isLatLong: boolean = false;
  public isLatLongSearch: boolean = false;
  public searchedLatLong: any;
  @Input() prefillValues: any = null;
  @Input() isComplete: boolean = false;
  @Input() showSearchButton: boolean = false;
  @Input() resultsPage: boolean = false;
  @Output() changedDates: EventEmitter<any> = new EventEmitter();
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  @Output() onLocationChanged: EventEmitter<any> = new EventEmitter();
  @Output() onResetDate :EventEmitter<any> = new EventEmitter();
  @ViewChild('location', {static: false}) locationInput: ElementRef;
  @ViewChild('dateStart', {static: false}) dateStartInput: ElementRef;
  @ViewChild('dateEnd', {static: false}) dateEndInput: ElementRef;
  @ViewChild('containerDates', {static: false}) containerDates: ElementRef;

  constructor(
    private loader:LoaderService,
    public fb: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private newenquiryService: NewEnquiryService,
  ) {
    this.newReportWidget = this.fb.group({
      "location": [''],
      "startDate": [''],
      "endDate": [''],
    });
    this.mapLocation = [-0.13, 51.52];
  }

  ngOnInit(): void {
    this.newenquiryService.getRegions({ filePrefixes: []})
      .subscribe(
        data => {
          this.regionListArr = data.regionsData;
        }, error => {}
      )
console.time("Time Taken to Fetch and Process Regions");
    this.newenquiryService.getRegions({ filePrefixes: [ 'A0', 'A1', 'A2' ], isGeojsonSearch: true })
      .subscribe(
        data => {
          this.regionListWithGeojsonArr = data.regionsData.map(region => {
            try {
              region.geojson = JSON.parse(region.geojson);
            } catch (error) {
              region.geojson = null;
            }
            return region;
          });
console.timeEnd("Time Taken to Fetch and Process Regions");
        },
        error => {}
      )
    this.mapsAPILoader.load().then(() => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.placesService = new google.maps.places.PlacesService(this.locationInput.nativeElement);
    });
  }
  ngAfterViewInit(): void {
      /*if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (this.mapPrefill === false) {
              const userCoords: [number, number] = [ position.coords.longitude, position.coords.latitude ];
              this.mapLocation = userCoords;
            }
          }, (error) => { console.error('Error getting user location:', error); }
        );
      }*/
      const app = this
      const form = this.newReportWidget
      let nextval = this.nextcount;
      //this.getGooglePlaces();
      if(!this.datepickerInput){
        this.datepickerInput = new Litepicker({
            parentEl: this.containerDates.nativeElement,
            element: this.dateStartInput.nativeElement,
            elementEnd: this.dateEndInput.nativeElement,
            autoRefresh: true,
            numberOfMonths: 2,
            numberOfColumns: 2,
            withInsights: false,
            allowRepick: true,
            minDate: app.isComplete && app.prefillValues ? moment(app.prefillValues.datestart, 'DD/MM/YYYY').toDate()
            : moment().add(7, 'day').toDate(),
            maxDate: app.isComplete && app.prefillValues ? moment(app.prefillValues.dateend, 'DD/MM/YYYY').toDate()
            : moment().add(5, 'years').toDate(),
            setup: (picker) => {
            picker.on('selected', (start, end) =>{
                const a = moment(end);
                const b = moment(start);
                console.log(start, end);
                form.controls.startDate.setValue(start.getDate()+'/'+(start.getMonth()+1)+'/'+start.getFullYear());
                form.controls.endDate.setValue(end.getDate()+'/'+(end.getMonth()+1)+'/'+end.getFullYear());
                let date1:any = new Date((start.getMonth()+1)+'/'+start.getDate()+'/'+start.getFullYear());
                let date2:any = new Date((end.getMonth()+1)+'/'+end.getDate()+'/'+end.getFullYear());
                app.changedDates.emit(moment(date2).diff(moment(date1), 'days'))
                let data: any = {
                  datestart: form.controls.startDate.value,
                  dateend: form.controls.endDate.value,
                }
                console.log(data);
                if(form.controls.location.value){
                  data.location = app.locationSelected;
                  data.place = app.locationValue;
                }
                if(nextval > 0){
                  app.onComplete.emit(data)
                }
                nextval++;
            }),
            picker.on('show',(e)=>{
              console.log(e);
              if(!this.datepickerInput.getStartDate()){
                this.datepickerInput.gotoDate(moment().add(7, 'day'));
                nextval =0;  
              }
            }),
            picker.on('hide',(e)=>{
              // app.changedDates.emit(moment(form.controls.endDate.value,"DD/MM/YYYY").diff(moment(form.controls.startDate.value,"DD/MM/YYYY"), 'days'))
              // let data: any = {
              //   datestart: form.controls.startDate.value,
              //   dateend: form.controls.endDate.value,
              // }
              // app.onComplete.emit(data)
              if(!this.datepickerInput.getStartDate()){
                app.onResetDate.emit();
                nextval =0;
              }
            })
          },
            plugins: ['mobilefriendly'],
        })
      }
      if(this.prefillValues){
          this.locationSelected = this.prefillValues.location
          form.controls.location.setValue(this.prefillValues.place)
          form.controls.startDate.setValue(this.prefillValues.datestart)
          form.controls.endDate.setValue(this.prefillValues.dateend)
          this.datepickerInput.setDateRange(moment(this.prefillValues.datestart, 'DD/MM/YYYY'), moment(this.prefillValues.dateend, 'DD/MM/YYYY'))
          let data: any = {
            datestart: form.controls.startDate.value,
            dateend: form.controls.endDate.value,
          }
          if(form.controls.location.value){
            data.location = this.prefillValues.location;
            data.place = this.prefillValues.place;
            data.origin = 'dashboard';
          }
          this.onComplete.emit(data);
          if (data.location) {
            if (data.location.is_region === false) {
              const lat = data.location?.geometry?.location?.lat ?? 51.52;
              const lng = data.location?.geometry?.location?.lng ?? -0.13;
              this.mapLocation = [ lng, lat ];
            } else if (data.location.is_region === true) {
              this.mapGeojson = data.location.map_geojson;
            }
            this.mapPin = data.location.map_pin;
            this.mapPrefill = true;
          }
      }
  }

  
  onEditClick(){
    setTimeout(() => {
      if(!this.isComplete){
        this.locationInput.nativeElement.focus()
        this.locationInput.nativeElement.select()
      }
    })
  }

  /*getGooglePlaces() {
    let x = this;
    let recentplaceObj: any = {};
    let inputLoc = <HTMLInputElement>document.getElementById("inputLocation");
    let places;
    this.mapsAPILoader.load().then(() => {
      places = new google.maps.places.Autocomplete(this.locationInput.nativeElement, {});
      google.maps.event.addListener(places, 'place_changed', () => {
        this.resetEnquiryData();
        this.onResultsLocationClick(places.getPlace());
      });
    });
    //x.postCodeFlag = false;
  }*/


  changeLocation(e){
    this.resetEnquiryData();
    //this.checkgooglepPlacesNetwork(e);
    const searchVal = e.target.value;
    const request = { input: searchVal };
    const fuse = new Fuse(this.regionListArr, {
      keys: [{
        name: 'name',
        weight: 0.8
      }],
      threshold: 0.15,
      minMatchCharLength: 1,
      distance: 100
    });
    this.autocompleteService.getPlacePredictions(request, (predictions, status) => {
      const regionSearch = fuse
        .search(searchVal)
        .slice(0, 5)
        .map(result => ({
          description: result.item.name,
          is_region: true,
          region_id: result.item.id,
          marker: '/assets/images/autocomplete-polygon.png',
          search_value: searchVal,
        }));
      const predictionResults = (status === google.maps.places.PlacesServiceStatus.OK && predictions)
        ? predictions.map(prediction => ({
            ...prediction,
            is_region: false,
            marker: '/assets/images/autocomplete-pin.png',
            search_value: searchVal,
          }))
        : [];
      this.locationResults = [... regionSearch, ... predictionResults];
    });
  }

  /*callGooglePlaces(e) {
    if ( this.isLatLong == true || this.isLatLongSearch == true )
      return;
    //this.isSomethingChanged = true;
    //this.insightObj.place = e.target.value;
    //this.place = e.target.value;
    //this.insightView = false;
    //this.showCreateLast = false;
    //this.showUseSite = false;
    this.loader.display(true);
    //this.removeInsightMap();
    this.getGooglePlaces();
    setTimeout(() => {
      // this.getHurricaneName();
      // this.setCalenderDate();
      // this.checkIfThelocationHasPostcode();
      // this.callEndDate();
      this.loader.display(false);
    }, 1000);
  }*/

  /*checkgooglepPlacesNetwork(e) {
    // if (navigator.onLine == true) {
      // this.preciseLocErr = false;
      // this.grpDrop = false;
      // this.insightView = false;
      this.isLatLong = false;
      this.isLatLongSearch = false;
      this.locationValue = e.target.value;
      if ( location == undefined )
        return;
      if (this.locationValue.indexOf(",") > -1) {
        let latLongArr = this.locationValue.split(',');
        if( this.isLatitude(latLongArr[0].trim()) &&  this.isLongitude(latLongArr[1].trim()) ) {
          this.isLatLong = true;
          let code = (e.keyCode ? e.keyCode : e.which);
          if ( code == 13 )
            this.searchByLatLong(e);
        } else {
          this.isLatLong = false;
          this.isLatLongSearch = false;
        }
      } 
      //If location is filled then hide validation message
      // this.showDates = false;
      // this.maxStartDt = new Date();
      // this.maxEndDt = new Date();
      // this.reqLocErr = (this.insightObj.place == '') ? true : false;
      // if (this.reqLocErr)
      //   this.removeInsightMap();
    // } else {
    //   //this.connectionService.display(true);
    //   this.loader.display(false);
    //   document.getElementById("inputLocation").blur();
    // }

  }*/

  /*searchByLatLong(LatLongValue) {
    //if (navigator.onLine == true) {
      let x = this;
      x.isLatLongSearch = true;
      x.loader.display(true);
      x.isLatLong = false; // Setting the variable to the default value
      x.searchedLatLong = LatLongValue;
      if (LatLongValue.indexOf(",") > -1) {
        //this.preciseLocErr = false;
      } else {
        //this.preciseLocErr = true;
        x.loader.display(false);
        return;
      } 
      let LatLongArr = LatLongValue.split(',');
      // x.weatherObj.lat = parseFloat(LatLongArr[0].trim());
      // x.weatherObj.lon = parseFloat(LatLongArr[1].trim());
      let geocoder = new google.maps.Geocoder;
      let latlng = { lat:parseFloat(LatLongArr[0]), lng: parseFloat(LatLongArr[1]) };
      geocoder.geocode({ 'location': latlng }, function (results, status) {
        if (status === 'OK') {
          if (results.length > 0) {
            // x.removeInsightMap();
            //x.getGooglePlaces();
            x.loader.display(false);
            x.loader.display(false);
            for (let r = 0; r < results.length; r++) {
              for (let i = 0; i < results[r].address_components.length; i++) {
                let postCodeInd = results[r].address_components[i].types.indexOf('postal_code');
                let countryInd = results[r].address_components[i].types.indexOf('country');
                let stateInd = results[r].address_components[i].types.indexOf('administrative_area_level_1');

                // if (postCodeInd > -1) {
                //   x.showDates = true;
                //   x.postcode = results[r].address_components[i].long_name;
                //   x.postCodeFlag = true;
                //   x.place = results[0];
                // } else {
                //   x.showDates = true;
                //   x.postcode = 'xxxxxx';
                //   x.postCodeFlag = true;
                //   x.place = results[0];
                // }

                // if (countryInd > -1) {
                //   x.countryName = results[r].address_components[i].short_name;
                //   x.countryLongName = results[r].address_components[i].long_name;
                // }
                // if (stateInd > -1) {
                //   x.state = results[r].address_components[i].long_name;
                // }
                //x.insightObj.place = x.place.formatted_address;
                //x.preciseLocErr = false;
              }
            }
            x.resetEnquiryData();
            x.onResultsLocationClick(results[0]);
            
          } else {
            // x.preciseLocErr = true;
            // x.showDates = false;
            // return;
          }
        } else {
          // x.preciseLocErr = true;
          // x.showDates = false;
          x.loader.display(false);
          // return;
        }
      }); 
    // } else {
    //   //this.connectionService.display(true);
    //   this.loader.display(false);
    // }
  }*/

  /*isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }*/

  /*isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }*/


  onMapPinDrop(lngLat) {
    this.onEditClick();
    const [lng, lat] = lngLat;
    const latLng = { lat, lng };
    let geocoder = new google.maps.Geocoder;
    const x = this;
    geocoder.geocode({ 'location': latLng }, function (results, status) {
      const latLngPlace = results[0];
      latLngPlace.address_components = latLngPlace.address_components || [];
      let formAddr = ['administrative_area_level_4', 'administrative_area_level_2', 'administrative_area_level_1', 'country', 'postal_code']
        .map(type => results.find(item => item.types.includes(type)))
        .filter(item => item && item.address_components && item.address_components[0] && item.address_components[0].long_name)
        .map(item => {
          const addressComponent = item.address_components[0];
          if (!latLngPlace.address_components.some(existing => existing.long_name === addressComponent.long_name)) {
            latLngPlace.address_components.push(addressComponent);
          }
          return addressComponent.long_name;
        })
        .join(', ');
      formAddr = "[ " + lat.toFixed(4) + ", " + lng.toFixed(4) + " ] - " + formAddr;    
      latLngPlace.formatted_address = formAddr;
      latLngPlace.is_region = false; 
      latLngPlace.region_id = '';  
      x.mapGeojson = {};
      x.mapPin = true;
      x.resultsLocationProcess(latLngPlace);
    });
  }

  onMapRegionSelect(region) {
    this.onEditClick();
    this.mapGeojson = region.geojson;
    this.mapPin = false;
    const place = { name: region.name, formatted_address: region.name, is_region: true, region_id: region.id };
    this.resultsLocationProcess(place);
    console.log(region)
  }


  /*searchNearestPlace(location: google.maps.LatLng, radius: number, lat: number, lng: number) {
    this.placesService.nearbySearch(
      {
        location,
        radius,
      },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
          const nearby = { 
            place_id: results[0].place_id,
            is_region: false,
            is_user_pin: true
          };
          this.onResultsLocationClick(nearby, lat, lng, radius);
        } else if (radius <= 250000) {
          this.searchNearestPlace(location, radius * 1.5, lat, lng);
        } else {
          console.error('No nearby places found within 250km');
          const latLng = { 
            is_region: false,
            region_id: '',
            is_user_pin: true,
            geometry: {
              location: {
                lat: () => lat,
                lng: () => lng
              }
            },
            formatted_address: "[ " + lat.toFixed(6) + ", " + lng.toFixed(6) + " ]"
          };
          this.resultsLocationProcess(latLng);
        }
      }
    );
  }*/


  onResultsLocationClick(result) {
    if (!result.is_region) {
      this.placesService.getDetails({ placeId: result.place_id }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          //if(!result.is_user_pin) {
            const placeLong = place.geometry.location.lng();
            const placeLat = place.geometry.location.lat();
            this.mapLocation = [ placeLong, placeLat ];
          //} else {
          //  place.geometry.location = new google.maps.LatLng(lat, lng);
          //  place.formatted_address = (rad > 10000 ? "[ " + lat.toFixed(3) + ", " + lng.toFixed(3) + " ] - Nr - " : "") + place.formatted_address;
          //}
          this.mapGeojson = {};
          this.mapPin = true;
          place.is_region = false; 
          place.region_id = '';
          this.resultsLocationProcess(place);
        } else {
          console.log(`Failed to fetch place details. Status: ${status}`);
        }
      });
    } else {
      const place = { name: result.description, formatted_address: result.description, is_region: true, region_id: result.region_id };
      this.newenquiryService.getRegionGeojson({ regionId:  result.region_id })
      .subscribe(
        data => {
          this.mapLocation = [51.52,-0.13];
          this.mapGeojson = data.regionGeojson;
          this.mapPin = false;
          this.resultsLocationProcess(place);
          console.log(data)
        }, error => {}
      )
    }
  }

  resultsLocationProcess(result){
    this.locationValue = this.locationInput.nativeElement.value;
    //this.datepickerInput.show();
    //if ( !this.isLatLongSearch ){
        this.locationValue = result.formatted_address ? result.formatted_address : this.locationInput.nativeElement.value;
    //} else {
    //    this.locationValue = this.searchedLatLong;
    //}
    this.newReportWidget.controls.location.setValue(this.locationValue);
    this.locationSelected = result;
    let data: any = {
      location: this.locationSelected,
    }
    data.place = this.locationValue;
    if(this.newReportWidget.controls.startDate.value && this.newReportWidget.controls.endDate.value){
      const a = moment(this.newReportWidget.controls.endDate.value, 'DD/MM/YYYY')
      const b = moment(this.newReportWidget.controls.startDate.value, 'DD/MM/YYYY')
      this.changedDates.emit(a.diff(b, 'days') +1)
      data.datestart = this.newReportWidget.controls.startDate.value
      data.dateend = this.newReportWidget.controls.endDate.value
    }
    this.onComplete.emit(data)
  }

  closeResultsLocation() {
    setTimeout(() => {
      this.locationResults = []
    }, 175)
  }

  handleSubmit(){
    this.closeResultsLocation()
    this.onSubmit();
  }

  onSubmit(){
    const values = this.newReportWidget.value
    if(!this.locationSelected && !this.isComplete){
      setTimeout(() => this.locationInput.nativeElement.focus())
      return false
    }
    //setTimeout(() => this.datepickerInput.show());
    return false;
    // else if((!values.startDate || !values.endDate) && this.datepickerInput){
    //   setTimeout(() => this.datepickerInput.show())
    //   return false
    // }


    // this.router.navigate(['/new-report'], { state: { searchResults: {
    //   location: this.locationSelected,
    //   datestart: values.startDate,
    //   dateend: values.endDate,
    // } } })
    
  }

  checkIfLocationIsSelected(e) {
    if ( !this.locationSelected ) {
      this.datepickerInput.hide()
    }
  }

  resetEnquiryData() {
    //this.datepickerInput.clearSelection();
    //this.newReportWidget.controls.startDate.setValue('');
    //this.newReportWidget.controls.endDate.setValue('');
    this.onLocationChanged.emit('Changed');
  }

  stylePredictions(description: string, searchValue: string): string {
    if (!searchValue) return description;
    const escapeRegExp = (string: string): string => {
      return string.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
    };
    const escapedSearchValue = escapeRegExp(searchValue);
    const match = description.match(/[,(]/);
    if (match) {
      const index = match.index;
      const splittingChar = match[0];
      const beforeSplit = description.slice(0, index).replace(new RegExp(`(${escapedSearchValue})`, 'gi'), '<strong>$1</strong>');
      let afterSplit = description.slice(index + 1).trim();

      if (splittingChar === '(') {
        afterSplit = `(${afterSplit}`;
      }
      return beforeSplit + ` <span class="text-xs text-gray-700 font-normal">${afterSplit}</span>`;
    }
    return description.replace(new RegExp(`(${escapedSearchValue})`, 'gi'), '<strong>$1</strong>');
  }
    /*stylePredictions(description: string, searchValue: string): string {
      if (!searchValue) return description;
      const match = description.match(/[,(]/);
      if (match) {
        const index = match.index;
        const splittingChar = match[0];
        const beforeSplit = description.slice(0, index).replace(new RegExp(`(${searchValue})`, 'gi'), '<strong>$1</strong>');
        let afterSplit = description.slice(index + 1).trim();
        if (splittingChar === '(') {
          afterSplit = `(${afterSplit}`;
        }
        return beforeSplit + ` <span class="text-xs text-gray-700 font-normal">${afterSplit}</span>`;
      }
      return description.replace(new RegExp(`(${searchValue})`, 'gi'), '<strong>$1</strong>');
    }*/

}
