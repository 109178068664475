import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from './../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { reducers } from './store/app.states';

import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { AuthEffects } from './store/effects/auth.effects';
import {
  TokenInterceptor, ErrorInterceptor
} from './services/token.interceptor';

import { NewEnquiryService } from './services/newenquiry.service';
import { EnquiriesService } from './services/enquiries.service';
import { ThresholdService } from './services/threshold.service';
import { LogoutService } from './services/logout.service';
import {PortfolioService} from './services/portfolio.service';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { StoreModule, ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeLogger } from 'ngrx-store-logger';

import { EffectsModule } from '@ngrx/effects';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import CalendarResultsTheme from './components/partials/reports/calendar-results/theme-calendar-results';

import { SigninComponent } from './components/signin/signin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NewreportWidgetComponent } from './components/partials/newreport-widget/newreport-widget.component';
import { NewreportWidgetWithInsightsComponent } from './components/partials/newreport-with-insights-widget/newreport-with-insights-widget.component';
import { NewreportMapComponent } from './components/partials/newreport-map/newreport-map.component';
import { EditreportWidgetWithInsightsComponent } from './components/partials/editreport-with-insights-widget/editreport-with-insights-widget.component';

import { SidebarComponent } from './components/partials/sidebar/sidebar.component';
import { ButtonComponent } from './components/partials/button/button.component';
import { MenuSidebarComponent } from './components/partials/menu-sidebar/menu-sidebar.component';
import { NewReportComponent } from './components/new-report/new-report.component';
import { EditReportComponent } from './components/edit-report/edit-report.component';
import { DataSourcesComponent } from './components/data-sources/data-sources.component';
import { MetswiftAnalyticsComponent } from './components/metswift-analytics/metswift-analytics.component';
import { NewsComponent } from './components/news/news.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LatestNewsWidgetComponent } from './components/partials/latest-news-widget/latest-news-widget.component';
import { MyPortfolioWidgetComponent } from './components/partials/my-portfolio-widget/my-portfolio-widget.component';
import { HowToUseWidgetComponent } from './components/partials/how-to-use-widget/how-to-use-widget.component';
import { DataSourcesWidgetComponent } from './components/partials/data-sources-widget/data-sources-widget.component';
import { ButtonTypeComponent } from './components/partials/reports/button-type/button-type.component';
import { ThresholdsItemComponent } from './components/partials/reports/thresholds-item/thresholds-item.component';
import { ButtonToggleComponent } from './components/partials/reports/button-toggle/button-toggle.component';
import { ReportComponent } from './components/report/report.component';
import { MyPortfolioComponent } from './components/my-portfolio/my-portfolio.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SearchReportsButtonComponent } from './components/partials/search-reports-button/search-reports-button.component';
import { NewsSingleWidgetComponent } from './components/partials/news-single-widget/news-single-widget.component';
import { EditableTitleComponent } from './components/partials/reports/editable-title/editable-title.component';
import { CalendarResultsComponent } from './components/partials/reports/calendar-results/calendar-results.component';
import { ResultsOverviewDataComponent } from './components/partials/reports/results-overview-data/results-overview-data.component';
import { ResultsHorizontalGraphDataComponent } from './components/partials/reports/results-horizontal-graph-data/results-horizontal-graph-data.component';
import { ResultsDownloadReportPdfComponent } from './components/partials/reports/results-download-report-pdf/results-download-report-pdf.component';
import { ResultsTableDataComponent } from './components/partials/reports/results-table-data/results-table-data.component';
import { WeatherTypeSmallComponent } from './components/partials/weather-type-small/weather-type-small.component';
import { BackgroundsComponent } from './components/partials/backgrounds/backgrounds.component';
import { ResultsGraphPlotComponent } from './components/partials/reports/results-graph-plot/results-graph-plot.component';
import { ReportAllComponent } from './components/report-all/report-all.component';
import { ResultsMapComponent } from './components/partials/reports/results-map/results-map.component';
import { ResultsTableAdvancedComponent } from './components/partials/reports/results-table-advanced/results-table-advanced.component';
import { ResultsNumericValuesComponent } from './components/partials/reports/results-numeric-values/results-numeric-values.component';
import { ResultsVerticalBarsComponent } from './components/partials/reports/results-vertical-bars/results-vertical-bars.component';
import { ResultsVerticalBarsStackComponent } from './components/partials/reports/results-vertical-bars-stack/results-vertical-bars-stack.component';
import { ResultsNumericValueComponent } from './components/partials/reports/results-numeric-value/results-numeric-value.component';
import { BadgeResultComponent } from './components/partials/reports/badge-result/badge-result.component';
import { LoaderService } from './services/loader.service';
import { EventService } from './services/event.service';
import { DashboardService } from './services/dashboard.service';
import { ConnectionService } from './services/connection.service';
import { ProbConvertModule } from "./prob-convert/prob-convert.module";
import { UnitConvertModule } from './unit-convert/unit-convert.module';
import { AgmCoreModule } from "@agm/core";
import { ENV } from './app.config';
import { from } from 'rxjs';
import { ClickOutsideModule } from 'ng4-click-outside';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ResultsTableRiskComponent } from './components/partials/reports/results-table-risk/results-table-risk.component';
import { UserService } from './services/user.service';
import { TxtMonthToNumPipe } from './pipes/txt-month-to-num.pipe';
import {ForgotComponent} from './components/forgot/forgot.component';
import {ResetComponent} from './components/reset/reset.component';
import {SetpasswordComponent} from './components/setpassword/setpassword.component';
import { WhatIsInsightComponent } from './components/partials/what-is-insight/what-is-insight.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CreateBulkEventsComponent } from './components/create-bulk-events/create-bulk-events.component';
import { KnowledgeBaseComponent } from './components/knowledge-base/knowledge-base.component';
import { MetswiftHLPComponent } from './components/metswift-hlp/metswift-hlp.component';
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['auth'], rehydrate: true })(reducer);
}
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return storeLogger()(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = environment.production ? [localStorageSyncReducer] : [localStorageSyncReducer, logger];


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  CalendarResultsTheme,
]);

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DashboardComponent,
    NewreportWidgetComponent,
    NewreportWidgetWithInsightsComponent,
    NewreportMapComponent,
    SidebarComponent,
    ButtonComponent,
    MenuSidebarComponent,
    NewReportComponent,
    DataSourcesComponent,
    MetswiftAnalyticsComponent,
    NewsComponent,
    ContactUsComponent,
    LatestNewsWidgetComponent,
    MyPortfolioWidgetComponent,
    HowToUseWidgetComponent,
    DataSourcesWidgetComponent,
    ButtonTypeComponent,
    ThresholdsItemComponent,
    ButtonToggleComponent,
    ReportComponent,
    MyPortfolioComponent,
    MyAccountComponent,
    TermsAndConditionsComponent,
    SearchReportsButtonComponent,
    NewsSingleWidgetComponent,
    EditableTitleComponent,
    CalendarResultsComponent,
    ResultsOverviewDataComponent,
    ResultsHorizontalGraphDataComponent,
    ResultsDownloadReportPdfComponent,
    ResultsTableDataComponent,
    WeatherTypeSmallComponent,
    BackgroundsComponent,
    ResultsGraphPlotComponent,
    ReportAllComponent,
    ResultsMapComponent,
    ResultsTableAdvancedComponent,
    ResultsNumericValuesComponent,
    ResultsVerticalBarsComponent,
    ResultsVerticalBarsStackComponent,
    ResultsNumericValueComponent,
    BadgeResultComponent,
    EditReportComponent,
    EditreportWidgetWithInsightsComponent,
    ResultsTableRiskComponent,
    TxtMonthToNumPipe,
    ForgotComponent,
    ResetComponent,
    WhatIsInsightComponent,
    SetpasswordComponent,
    CreateBulkEventsComponent,
    KnowledgeBaseComponent,
    MetswiftHLPComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([ AuthEffects ]),
    AppRoutingModule,
    ReactiveFormsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1Ijoidml0YWxhZ2VuY3kiLCJhIjoiY2tkb3N0cmQ1MXV2ZjJ6cm9pdG1yZ3g5aiJ9.32PJ8demu-YJrkusdKfyHg', // Optional, can also be set per map (accessToken input of mgl-map)
      geocoderAccessToken: 'pk.eyJ1Ijoidml0YWxhZ2VuY3kiLCJhIjoiY2tkb3N0cmQ1MXV2ZjJ6cm9pdG1yZ3g5aiJ9.32PJ8demu-YJrkusdKfyHg' // Optional, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),
    FullCalendarModule,
    NgxSmartModalModule.forRoot(),
    UnitConvertModule,
    ProbConvertModule,
    AgmCoreModule.forRoot({
      apiKey: ENV.GOOGLE_API_KEY,
      libraries: ["places"]
    }),
    ClickOutsideModule,
    SlickCarouselModule,
    AngularMultiSelectModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    LoaderService,
    LogoutService,
    ConnectionService,
    DashboardService,
    NewEnquiryService,
    EnquiriesService,
    ThresholdService,
    ProbConvertModule,
    EventService,
    PortfolioService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    UserService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
