<div class="flex flex-wrap overflow-hidden relative h-full">
    <div class="p-4 md:p-6 lg:p-12 flex-1 min-w-0">
        <app-backgrounds></app-backgrounds>
        <div class="w-full relative z-10">
            <div class="flex flex-wrap -mx-4 lg:-mx-6 justify-between mb-3 md:mb-5 lg:mb-8">
                <div class="px-4 lg:px-6 w-auto">
                    <h1 class="font-semibold text-xl lg:text-2xl">{{ title }}</h1>
                </div>
                <div class="px-4 lg:px-6 flex flex-wrap items-center">
                    <app-search-reports-button></app-search-reports-button>
                </div>
            </div>
            <div class="w-full py-2 text-right">
                
            </div>
            <div class="block md:flex mb-3 lg:mb-6">
                <div class="w-full md:w-2/3">
                    <app-what-is-insight *ngIf="unlimited != 1"></app-what-is-insight>
                </div>
                <div class="w-full md:w-1/3 text-right mt-2 md:mt-0">
                    <!--<a href="javascript:void(0)" [routerLink]="['/create-bulk-events']" class="bg-primarylighter text-white px-4 py-2 rounded-full text-xs font-bold">Create Bulk events</a>-->
                    <a href="javascript:void(0)" [routerLink]="['/create-bulk-events']" class="hidden bg-primarylighter text-white px-4 py-2 rounded-full text-xs font-bold">Create Bulk events</a>
                </div>
                
            </div>

            
            <div class="flex flex-wrap -mx-4 lg:-mx-6">
               <app-newreport-with-insights-widget (onResetDate)="removeSelection()" (onComplete)="changeSearchStatus($event)" (changedDates)="calculateInsightsDates($event)" (onLocationChanged)="locationChanged($event)" [prefillValues]="searchState" class="w-full mb-4 lg:mb-6 px-4 lg:px-6"></app-newreport-with-insights-widget>
                <div class="w-full mb-4 lg:mb-6 px-4 lg:px-6" *ngIf="locationName && riskArray.length>0">
                    <div class="button-report-warning lg:flex flex-wrap justify-between">
                        <div class="lg:mr-auto flex flex-wrap items-center">
                            <div class="btn-warning ">
                                Warning
                            </div>
                            <div class="ml-4 text-sm items-center">
                                <div class="w-full">
                                    Risk of Nat Cat between {{stDate}} and {{endDt}}:
                                </div>
                                <div class="w-full">
                                    <span *ngIf="riskArray.includes('tornadoes')" class="mr-2 inline-block">
                                        <strong>Tornado
                                        <!-- <svg class="relative fill-current inline-block" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path d="M9,7c0-0.6-0.4-1-1-1H5v2h2v6H5v2h6v-2H9V7z"></path> <circle cx="7.5" cy="2.5" r="1.5"></circle></svg> -->
                                        <span [ngClass]="riskValues.tornadoes ? 'bg-red' : 'bg-yellow'" class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                                            <img class="w-3 -mt-1 inline-block" src="../../../assets/images/info-black.png" alt="info">
                                            <div class="tooltip">
                                                <p> Risk present for a Tornado in the following classifications: {{riskclasess['tornadoes'].join(', ')}}</p>
                                                <!-- <p> Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1950, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Only available for locations in the United States of America.</p> -->
                                            </div>
                                        </span>
                                    </strong> 
                                    </span>
                                    <span *ngIf="riskArray.includes('hurricanes')" class="mx-2 inline-block">
                                        <span class="font-bold whitespace-nowrap">{{hurricaneName}}
                                            <span [ngClass]="riskValues.hurricanes ? 'bg-red' : 'bg-yellow'" class="relative tooltipwrp ml-1 text-primary rounded-full w-5 h-5 inline-block text-center">
                                                <img class="w-3 -mt-1 inline-block" src="../../../assets/images/info-black.png" alt="info">
                                                <div class="tooltip">
                                                    <p> Risk present for a {{hurricaneName}} in the following classifications: {{riskclasess['hurricanes'].join(', ')}}</p>
                                                    <!-- <p>Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1850, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Locally accepted classifications.</p> -->
                                                </div>
                                            </span>
                                        </span>
                                    </span>
                                    <span *ngIf="riskArray.includes('wildfire')" class="mx-2 inline-block">
                                        <span class="font-bold whitespace-nowrap">Wildfire
                                            <span [ngClass]="riskValues.wildfire ? 'bg-red' : 'bg-yellow'" class="relative tooltipwrp ml-1 bg-red text-primary rounded-full w-5 h-5 inline-block text-center">
                                                <img class="w-3 -mt-1 inline-block" src="../../../assets/images/info-black.png" alt="info">
                                                <div class="tooltip">
                                                    <p *ngIf="riskValues.wildfire"> >1% wildfire risk present at this location</p>
                                                    <p *ngIf="!riskValues.wildfire"> 0-1% risk of wildfire present at this location</p>
                                                    <!-- <p>A weekly wildfire risk model calculated using historical data since 1950, utilising both atmospheric and land usage data, generating a single risk value of risk of wildfire conditions occurring on a localised basis.</p> -->
                                                </div>
                                            </span>
                                        </span>
                                    </span>
                                    <span *ngIf="riskArray.includes('earthquake')" class="mx-2 inline-block">
                                        <span class="font-bold whitespace-nowrap">Earthquake
                                            <span [ngClass]="riskValues.earthquake ? 'bg-red' : 'bg-yellow'" class="relative tooltipwrp  ml-1 bg-red text-primary rounded-full w-5 h-5 inline-block text-center">
                                                <img class="w-3 -mt-1 inline-block" src="../../../assets/images/info-black.png" alt="info">
                                                <div class="tooltip">
                                                    <p> Risk present for a Earthquake in the following classifications: {{riskclasess['earthquake'].join(', ')}}</p>
                                                    <!-- <p>Aggregated on an annual binary basis, and reported as an annual occurrence. Hyper local data, reaching back to 1900, means our statistics achieve highly specific local relevance. We also grade the severity of the natural catastrophe, based on this detailed data. Attain probability percentages of risk in response to your parameters. Moment magnitude severity M4 - M9 classification.</p> -->
                                                </div>
                                            </span>
                                        </span>
                                    </span>
                                   
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                
            </div>


            <div class="flex flex-wrap min-h-full -mx-4 lg:-mx-6">
                <div class="w-full flex h-full flex-col items-stretch px-4 lg:px-6 mb-3 lg:mb-6
                ">
                    <h3 *ngIf="weatherArray.length > 0 || prev_weather.length > 0" class="text-primary font-bold mb-3">Select weather risks <i class="ml-4 text-xs italic font-light" *ngIf="unlimited != 1">31 days per insight</i></h3>
                    <div *ngIf="weatherArray.length>0 || prev_weather.length > 0"
                        class="flex flex-wrap -mx-1 sm:-mx-2 flex-1 h-full mb-height">
                        <app-button-type [class.isDisabled]="!weatherArray.includes('rain') ? 'isDisabled' : ''" [info]="infoRain"
                            [dataActive]="selectedTypes.rain" [dataNotActive]="hasTypeSelected && !selectedTypes.rain"
                            (activeType)="typeSelected($event)" type="rain"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('wind') ? 'isDisabled' : ''" [info]="infoWind"
                            [dataActive]="selectedTypes.wind" [dataNotActive]="hasTypeSelected && !selectedTypes.wind"
                            (activeType)="typeSelected($event)" type="wind"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('temperature') ? 'isDisabled' : ''"
                            [info]="infoTemperature" [dataActive]="selectedTypes.temperature"
                            [dataNotActive]="hasTypeSelected && !selectedTypes.temperature" (activeType)="typeSelected($event)"
                            type="temperature"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('snow') ? 'isDisabled' : ''" [info]="infoSnow"
                            [dataActive]="selectedTypes.snow" [dataNotActive]="hasTypeSelected && !selectedTypes.snow"
                            (activeType)="typeSelected($event)" type="snow"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('hail') ? 'isDisabled' : ''" [info]="infoHail"
                            [dataActive]="selectedTypes.hail" [dataNotActive]="hasTypeSelected && !selectedTypes.hail"
                            (activeType)="typeSelected($event)" type="hail"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('dust') ? 'isDisabled' : ''" [info]="infoDust"
                            [dataActive]="selectedTypes.dust" [dataNotActive]="hasTypeSelected && !selectedTypes.dust"
                            (activeType)="typeSelected($event)" type="dust"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('fog') ? 'isDisabled' : ''" [info]="infoFog"
                            [dataActive]="selectedTypes.fog" [dataNotActive]="hasTypeSelected && !selectedTypes.fog"
                            (activeType)="typeSelected($event)" type="fog"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                        <app-button-type [class.isDisabled]="!weatherArray.includes('storm') ? 'isDisabled' : ''" [info]="infoStorm"
                            [dataActive]="selectedTypes.storm" [dataNotActive]="hasTypeSelected && !selectedTypes.storm"
                            (activeType)="typeSelected($event)" type="storm"
                            class="relative button-type-weather px-1 sm:px-2 my-1 sm:my-2 flex items-stretch"></app-button-type>
                    </div>

                 
                    <!-- <h3 *ngIf="riskArray.length > 0 || riskExposureArray.length > 0" class="text-primary font-bold mb-3">Select Nat Cat risks <i class="ml-4 text-xs italic font-light">7 days per insight</i></h3> -->
                    <h3 *ngIf="riskArray.length > 0 || riskExposureArray.length > 0" class="text-primary font-bold mb-3">
                        Select Nat Cat risks 
                        <i class="ml-4 text-xs italic font-light" *ngIf="unlimited != 1">7 days per insight</i>
                    </h3>
                    
                    <div class="flex flex-wrap -mx-2 flex-1">
                        <app-button-type *ngIf="infoEarthquake && (riskArray.includes('earthquake') || riskExposureArray.includes('earthquake'))" [class.isDisabled]="!riskArray.includes('earthquake') && riskExposureArray.includes('earthquake') ? 'isDisabled' : ''" [info]="infoEarthquake" [dataActive]="selectedTypes.earthquake" [dataNotActive]="hasTypeSelected && !selectedTypes.earthquake" (activeType)="typeSelected($event)" type="earthquake" [hurricaneName]="hurricaneName" class="relative px-1 sm:px-2 my-1 sm:my-2 2xl:w-1/4 md:w-1/3 sm:w-1/3 w-1/2 nat-cat-report"></app-button-type>
                        <app-button-type *ngIf="infoTornadoes && (riskArray.includes('tornadoes') || riskExposureArray.includes('tornadoes'))" [class.isDisabled]="!riskArray.includes('tornadoes') && riskExposureArray.includes('tornadoes') ? 'isDisabled' : ''" [info]="infoTornadoes" [dataActive]="selectedTypes.tornado" [dataNotActive]="hasTypeSelected && !selectedTypes.tornado" (activeType)="typeSelected($event)" type="tornado" [hurricaneName]="hurricaneName" class="relative px-1 sm:px-2 my-1 sm:my-2 2xl:w-1/4 md:w-1/3 sm:w-1/3 w-1/2 nat-cat-report"></app-button-type>

                        <app-button-type *ngIf="infoHurricane && (riskArray.includes('hurricanes') || riskExposureArray.includes('hurricanes'))" [class.isDisabled]="!riskArray.includes('hurricanes') && riskExposureArray.includes('hurricanes') ? 'isDisabled' : ''" [info]="infoHurricane" [dataActive]="selectedTypes.hurricane" [dataNotActive]="hasTypeSelected && !selectedTypes.hurricane" (activeType)="typeSelected($event)" type="hurricane" [hurricaneName]="(hurricaneName.replace(' (Hu)','')).replace(' (Cy)','').replace(' (CS)','').replace(' (Ty)','')" class="relative px-1 sm:px-2 my-1 sm:my-2 2xl:w-1/4 md:w-1/3 sm:w-1/3 w-1/2 nat-cat-report"></app-button-type>

                        <app-button-type *ngIf="infoWildfire && (riskArray.includes('wildfire') || riskExposureArray.includes('wildfire'))" [class.isDisabled]="!riskArray.includes('wildfire') && riskExposureArray.includes('wildfire') ? 'isDisabled' : ''" [info]="infoWildfire" [dataActive]="selectedTypes.wildfire" [dataNotActive]="hasTypeSelected && !selectedTypes.wildfire" (activeType)="typeSelected($event)" type="wildfire" [hurricaneName]="hurricaneName" class="relative px-1 sm:px-2 my-1 sm:my-2 2xl:w-1/4 md:w-1/3 sm:w-1/3 w-1/2 nat-cat-report"></app-button-type>
                    </div>

                </div>

                <div class="w-full px-6 mb-6">
                    <div class="flex flex-wrap -mx-2 flex-1">
                        <div class="flex-1 px-2" *ngIf="hasTypeSelected > 0 && selectedDates">

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="sidebar-report bg-gray-200 xl:rounded-tl-xl min-h-screen flex items-center w-full xl:w-2/5">
        <div class="py-6 px-3 lg:px-6 xl:py-12 xl:px-12 w-full text-center min-h-screen sticky top-0 flex-col">
            <h3 class="text-xl lg:text-2xl font-semibold mb-3 lg:mb-4">Your enquiries</h3>
            <div class="my-6 lg:my-12">
                <div class="font-semibold mb-2">Location</div>
                <div class="text-sm font-light" *ngIf="locationName.length">{{ locationName }}</div>
                <div class="text-sm font-light" *ngIf="!locationName.length">Select your location</div>
            </div>
            <div class="my-6 lg:my-12">
                <!-- <div class="font-semibold mb-2">Selected dates <i class="ml-4 text-xs italic font-light">31 days per insight</i></div> -->
                <div class="font-semibold mb-2">Selected dates</div>
                <div class="text-xs font-light mb-2" *ngIf="datesName.length" innerHTML={{datesName}}></div>
                <div class="text-xs font-light mb-2" *ngIf="!datesName.length">Select your dates</div>

                <!-- <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="datesInsights > 1">{{ datesInsights }} insight periods</div>
                <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="datesInsights == 1">{{ datesInsights }} insight period</div>
                <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold opacity-50" *ngIf="!datesInsights">Number of insights period</div> -->
            </div>
            <div class="my-6 lg:my-12">

                <div class="font-semibold mb-2" *ngIf="hasTypeSelected>1">Selected Weather/Nat Cat Risks</div>
                <div class="font-semibold mb-2" *ngIf="hasTypeSelected==1">Selected Weather/Nat Cat Risk</div>
                <div class="font-semibold mb-2" *ngIf="!hasTypeSelected">Select your weather/Nat Cat type</div>

                <div class="flex justify-center items-center opacity-50" *ngIf="hasTypeSelected">
                    <!-- <div class="w-16 h-16 mb-3 mx-2">
                        <app-weather-type-small type="rain"></app-weather-type-small>
                    </div>
                    <div class="w-16 h-16 mb-3 mx-2">
                        <app-weather-type-small type='wind'></app-weather-type-small>
                    </div> -->
                </div>


                <div class="inline-flex mx-2" *ngFor="let item of selectedWeatherTypes">
                    <div class="w-16 h-16 mb-3">
                        <app-weather-type-small [type]="item"></app-weather-type-small>
                    </div>
                </div>
                <div class="w-full">
                    <!-- <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="hasTypeSelected > 1">{{ hasTypeSelected }} weather/Nat Cat risks</div>
                    <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold" *ngIf="hasTypeSelected == 1">{{ hasTypeSelected }} weather/Nat Cat risk</div>
                    <div class="btn-gradient py-2 text-xs px-5 inline-block mx-auto font-semibold opacity-50" *ngIf="!hasTypeSelected">Select weather/Nat Cat risks</div> -->
                </div>

            </div>
            <div class="my-12" *ngIf="hasTypeSelected && locationName.length && datesName.length">
                <div class="font-semibold mb-2 text-lg" *ngIf="unlimited !=1">Your total</div>
                <div class="btn-gradient py-2 text-base px-6 inline-block mx-auto text-sm w-40 font-light" *ngIf="insightCount > 1 && unlimited != 1"><span class="text-xl font-semibold">{{ insightCount }}</span> insights</div>
                <div class="btn-gradient py-2 text-base px-6 inline-block mx-auto text-sm w-40 font-light" *ngIf="insightCount <= 1 && unlimited != 1"><span class="text-xl font-semibold">{{ insightCount }}</span> insight</div>
                <div class="w-full mb-1 lg:mb-4" *ngIf="incompletew">
                    <h4 class="text-base font-bold leading-4 mb-1">One or more variables returned incomplete data for your search 
                        <span class="relative tooltipwrp  ml-1 text-primary rounded-full w-5 h-5 inline-block text-center align-top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                              </svg>
                            <div class="tooltip sidebar">
                                <p>Some days within your search period did not contain enough valid historical data to generate useful insights, however you may re-select the weather type to view the results for the dates that we were able to create insights for. A report has been sent to our team to investigate.</p>
                            </div>
                        </span>
                    </h4> 
                    <p class="text-sm font-normal leading-4">Please re-select any variables for which you would nonetheless like a report: <br/>{{incomplt_w_arr.join(', ')}}</p> 
                </div>
                <div class="w-full mb-3 lg:mb-3" *ngIf="missingw">
                    <h4 class="text-primary font-bold leading-4 mb-1">One or more variables returned no data for your search
                        <span class="relative tooltipwrp  ml-1 text-primary rounded-full w-5 h-5 inline-block text-center align-top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                              </svg>
                            <div class="tooltip sidebar">
                                <p>No weather stations nearby to your search location had enough valid data to generate useful insights for your requested dates for the variables listed. A report has been sent to our team to investigate.</p>
                            </div>
                        </span>
                    </h4> 
                    <p class="text-sm font-normal leading-4">These variables have been removed from your search and cannot be re-selected: <br/>{{missing_w_arr.join(', ')}}</p> 
                </div>

                <button (click)="checkIfInsightCountIsUnderLimit()" class="mt-12 rounded-full text-base w-full font-normal bg-primary button-buy px-4 py-3 text-white flex items-center justify-center">
                    Get report 
                    <!-- (click)="onClickBuyInsights()" -->
                    <svg *ngIf="unlimited != 1" xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current stroke-current ml-4" viewBox="0 0 12 12"><g stroke-width="1"><polyline points="0.5 0.5 2.5 0.5 2.5 8.5 9.5 8.5 11.5 3.5 2.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline> <circle cx="3" cy="11" r="1" data-stroke="none" stroke="none"></circle> <circle cx="10" cy="11" r="1" data-stroke="none" stroke="none"></circle></g></svg>
                </button>
               

            </div>
            <div class="my-6 lg:my-12" *ngIf="!(hasTypeSelected && locationName.length && datesName.length)">
                <div class="font-semibold mb-2 text-lg" *ngIf="unlimited != 1">Your total</div>
                <div class="btn-gradient py-2 text-base px-6 inline-block mx-auto text-sm w-40 font-light" *ngIf="unlimited != 1">Your insights</div>
                <div class="w-full mb-1 lg:mb-4" *ngIf="incompletew">
                    <h4 class="text-base font-bold leading-4 mb-1">One or more variables returned incomplete data for your search
                        <span class="relative tooltipwrp  ml-1 text-primary rounded-full w-5 h-5 inline-block text-center align-top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                              </svg>
                            <div class="tooltip sidebar">
                                <p>Some days within your search period did not contain enough valid historical data to generate useful insights, however you may re-select the weather type to view the results for the dates that we were able to create insights for. A report has been sent to our team to investigate.</p>
                            </div>
                        </span>
                    </h4> 
                    <p class="text-sm font-normal leading-4">Please re-select any variables for which you would nonetheless like a report: <br/>{{incomplt_w_arr.join(', ')}}</p> 
                </div>
                <div class="w-full mb-3 lg:mb-3" *ngIf="missingw">
                    <h4 class="text-base font-bold leading-4 mb-1">One or more variables returned no data for your search
                        <span class="relative tooltipwrp  ml-1 text-primary rounded-full w-5 h-5 inline-block text-center align-top">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                              </svg>
                            <div class="tooltip sidebar">
                                <p>No weather stations nearby to your search location had enough valid data to generate useful insights for your requested dates for the variables listed. A report has been sent to our team to investigate.</p>
                            </div>
                        </span>
                    </h4> 
                    <p class="text-sm font-normal leading-4">These variables have been removed from your search and cannot be re-selected: <br/>{{missing_w_arr.join(', ')}}</p> 
                </div>

                <div class="mt-12 opacity-50 pointer-events-none rounded-full text-base w-full font-normal bg-primary px-4 py-3 text-white flex items-center justify-center">
                    Get report
                    <svg *ngIf="unlimited != 1"  xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-current stroke-current ml-4" viewBox="0 0 12 12"><g stroke-width="1"><polyline points="0.5 0.5 2.5 0.5 2.5 8.5 9.5 8.5 11.5 3.5 2.5 3.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></polyline> <circle cx="3" cy="11" r="1" data-stroke="none" stroke="none"></circle> <circle cx="10" cy="11" r="1" data-stroke="none" stroke="none"></circle></g></svg>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="riskDataNotFoundMessage" class="col-12 watchevent">
  <div class="watcheventwrp commonclr">
    <a (click)="closeOverlay()" class="closewatch">
      <img src="../assets/images/close-popup.png" alt="">
    </a>
    <div class="eventcost">
      <h3>This location has <span [innerHtml]="weatherExistsStr"></span> data, please close this message and press 'Get report' to access these results, however the following data is not available:</h3>
      <h4><span *ngFor="let weather of weatherArr;"><strong>{{weather}}</strong></span></h4>
      <h3>Our support team has been notified and may be able to produce a manual report, please visit our <a href="javascript:void(0)" routerLink="/contact-us">contact page</a> to request specific thresholds.</h3>
    </div>
  </div>
</div>
<div *ngIf="overlayFlag" class="col-12 watchevent">
    <div class="watcheventwrp commonclr">
      <a (click)="closeOverlay()" class="closewatch">
        <img src="../assets/images/close-popup.png" alt="">
      </a>
      <div class="eventcost">
        <h3 *ngIf="getCStationAPIErrorCode == 400 || 404">No weather data is available for this location. Our support team has been notified and may be able to produce a manual report, please visit our <a href="javascript:void(0)" routerLink="/contact-us">contact page</a> to request specific thresholds.</h3>
        <h3 *ngIf="getCStationAPIErrorCode == 500">Something went wrong, Our support team has been notified and may be able to produce a manual report, please visit our <a href="javascript:void(0)" routerLink="/contact-us">contact page</a> to request specific thresholds.</h3>
      </div>
    </div>
</div>

 <!-- modal for get report data limit -->
 <ngx-smart-modal [closable]="false" #reportLimit identifier="reportLimit">
    <div class="container max-w-md mx-auto">
        <div class="p-6 overlay-color rounded">
            <h3 class="mb-8 text-sm text-white font-normal md:text-lg text-center">Request exceeds {{maxInsightAllowed}} insights - Are you sure?</h3>
            <div class="w-full text-center">
            <button (click)="onClickBuyInsights()" type="submit" class="rounded-full h-10 px-8 mr-2 bg-white text-primarylighter text-sm font-bold text-center">Yes</button>
            <button (click)="closePopup()" type="button" class="rounded-full h-10 px-8 bg-white text-primarylighter text-sm font-bold text-center">No</button>
        </div>
        </div>
    </div>
</ngx-smart-modal>