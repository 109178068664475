<div class="w-full mx-auto max-w-xs mb-3 thresholds-item">
    <div class="flex flex-wrap justify-between mb-3 items-center">
            <div class="mr-auto">
                <app-button-toggle (toggle)="onOrderClick(item.slug)" *ngFor="let item of orderItems;" [dataActive]='item.active' class="mr-2 w-auto">{{ item.name }}</app-button-toggle>
            </div>
            <div class="ml-auto">
                <app-button-toggle (toggle)="onUnitsClick($event)" type="{{ type }}" *ngFor="let unit of units; index as i; first as isFirst" [dataActive]='unitSelected === unit' class="ml-2 w-auto"><span [innerHtml]="weatherSymbol"></span> {{ unit }}</app-button-toggle>
            </div>
    </div>
    <div class="mb-2">
        <!-- <div class="w-full mb-2" *ngIf="order === 'ASC'"> -->
        <div class="w-full mb-2">
            <div class="bg-white rounded-full text-sm text-sm p-1 items-center pr-3 flex flex-wrap justify-between font-medium">
                <div class="bg-yellow-light px-3 rounded-full py-1 text-primary font-semibold text-xs">Threshold 1<i class="fa-solid fa-circle-info"></i></div>
                <div class="ml-2">
                    <button (click)="onClickLessMod()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        -
                    </button>
                    <!--<span class="text-primary text-sm">{{ counterMod }}</span>-->
                    <input type="number" id="counterModInput" class="w-16 text-primary text-sm text-center rounded-full border-gray-400 counter-input" [(ngModel)]="counterMod" (paste)="$event.preventDefault()" (keypress)="validateInput($event)" (keyup)="validateChanged($event)" (input)="enforceMaxLength($event)"/>
                    <button (click)="onClickMoreMod()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        +
                    </button>
                    <span class="text-gray-600 text-xs"><span [innerHtml]="weatherSymbol"></span>{{ unitSelected }}</span>
                </div>
            </div>
        </div>
        <div class="w-full mb-2">
            <div class="bg-white rounded-full text-sm text-sm p-1 items-center pr-3 flex flex-wrap justify-between font-medium">
                <div class="bg-red-dark px-3 rounded-full py-1 text-white font-semibold text-xs">Threshold 2</div>
                <div class="ml-2">
                    <button (click)="onClickLessSev()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        -
                    </button>
                    <!--<span class="text-primary text-sm">{{ counterSev }}</span>-->
                    <input type="number" id="counterSevInput" class="w-16 text-primary text-sm text-center rounded-full border-gray-400 counter-input" [(ngModel)]="counterSev" (paste)="$event.preventDefault()" (keypress)="validateInput($event)" (keyup)="validateChanged($event)" (input)="enforceMaxLength($event)"/>
                    <button (click)="onClickMoreSev()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        +
                    </button>
                    <span class="text-gray-600 text-xs"><span [innerHtml]="weatherSymbol"></span>{{ unitSelected }}</span>
                </div>
            </div>
        </div>
        <!-- <div class="w-full mb-2" *ngIf="order === 'DESC'">
            <div class="bg-white rounded-full text-sm text-sm p-1 items-center pr-3 flex flex-wrap justify-between font-medium">
                <div class="bg-yellow-light px-3 rounded-full py-1 text-primary font-semibold text-xs">Moderate</div>
                <div class="ml-2">
                    <button (click)="onClickLessMod()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        -
                    </button>
                    <span class="text-primary text-sm">{{ counterMod }}</span>
                    <button (click)="onClickMoreMod()" class="w-5 h-5 bg-gray-200 inline-flex rounded-full items-center justify-center mx-2">
                        +
                    </button>
                    <span class="text-gray-600 text-xs"><span [innerHtml]="weatherSymbol"></span>{{ unitSelected }}</span>
                </div>
            </div>
        </div> -->
        <div class="w-full" *ngIf="isChanged">
            <button (click)="updateButtonClicked()" class="text-sm font-semibold btn-update text-white block w-full py-2 px-4 rounded-full">Update</button>
        </div>
        <div class="w-full" *ngIf="isUnitChanged">
            <button (click)="updateUnitButtonClicked()" class="text-sm font-semibold btn-update text-white block w-full py-2 px-4 rounded-full">Update</button>
        </div>
    </div>
</div>