<div class="w-full h-full items-stretch flex flex-wrap items-center">
    <div class="icon-weather icon-weather--{{ type }} flex items-center">
        <div class="mx-auto my-auto" *ngIf="type === 'rain'">
            <img src="assets/images/icons-weather/rain.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'wind'">
            <img src="assets/images/icons-weather/wind.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'temperature'">
            <img src="assets/images/icons-weather/temperature.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'snow'">
            <img src="assets/images/icons-weather/snow.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'hail'">
            <img src="assets/images/icons-weather/hail.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'storm'">
            <img src="assets/images/icons-weather/storm.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'dust'">
            <img src="assets/images/icons-weather/dust.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'fog'">
            <img src="assets/images/icons-weather/fog.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'tornado'">
            <img src="assets/images/icons-weather/hurricane.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'hurricane'">
            <img src="assets/images/icons-weather/hurricane.png" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'earthquake'">
            <img src="assets/images/icons-weather/earthquakes.svg" class="icon" />
        </div>
        <div class="mx-auto my-auto" *ngIf="type === 'wildfire'">
            <img src="assets/images/icons-weather/wildfire.png" class="icon" />
        </div>
    </div>
    <ng-content></ng-content>
</div>
