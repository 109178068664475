import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../../store/app.states';
import { Observable } from 'rxjs';
import { NewEnquiryService } from '../../services/newenquiry.service';
import * as moment from 'moment';
import { threadId } from 'worker_threads';
import { LoaderService } from '../../services/loader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ENV } from '../../app.config';
import { UserService } from 'src/app/services/user.service';
import { error } from 'console';

@Component({
  selector: 'app-edit-report',
  templateUrl: './edit-report.component.html',
  styleUrls: ['./edit-report.component.scss']
})

export class EditReportComponent implements OnInit, AfterViewInit {
    getState: Observable<any>;
    searchState: any = null;
    title : string = 'Edit enquiry';
    infoEarthquake: string = '<span class="text-sm w-full block">Aggregated on an annual binary basis, and reported as an annual occurrence. Hyper local data, reaching back to 1900, means our statistics achieve highly specific local relevance. We also grade the severity of the natural catastrophe, based on this detailed data. Attain probability percentages of risk in response to your parameters. Moment magnitude severity M4 – M9 classification.</span>';
    infoWildfire: string = '<span class="text-sm w-full block">Hyperlocal wildfire occurrence risk model using historical wildfire event data since 1950, utilizing both atmospheric data (inc. dry days, high daytime temperatures, mean wind and humidity) and high resolution land type data with applied latitudinal, seasonal and slope factor adjustments. Two outputs will be available, the ‘All data’ (1950-2019) and ‘Since 2000’ (2000-2019) options providing a localised,weekly, wildfire development risk score.</span>';
    infoTornadoes: string = '<span class="text-sm w-full block">Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1950, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Only available for locations in the United States of America</span>';
    infoHurricane: string = '<span class="text-sm w-full block">Aggregated on a weekly basis, and reported as a weekly occurrence. Hyper local data, reaching back to 1850, means your results achieve highly specific local relevance. Attain risk probability percentages in response to your parameters. We also grade the severity of the natural catastrophe, based on this detailed data. Locally accepted classifications.</span>';
    infoRain: string = '<span class="text-sm w-full block">The risk for the selected period from the accumulated rainfall total, aggregated on a daily, weekly, or monthly basis. Available units are mm and inches. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoWind: string = '<span class="text-sm w-full block">The risk for the selected period from wind speed (10-minute averaged wind) or wind gust (2-10 second interval instantaneous wind) reports. Available units are miles per hour and metres per second. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoTemperature: string = '<span class="text-sm w-full block">The risk for the selected period from the maximum, mean, and minimum temperature reports. Available units are degrees Celsius and degrees Fahrenheit. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoSnow: string = '<span class="text-sm w-full block">The risk for the selected period from the total accumulated snow amount. Available units are cm and inches. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoHail: string = '<span class="text-sm w-full block">The risk for the selected period from hail reports by the weather station. If at any point in the day hail is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Additionally, this includes other forms of frozen precipitation such as freezing rain. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoDust: string = '<span class="text-sm w-full block">The risk for the selected period from duststorm or sandstorm reports by the weather station. If at any point in the day a duststorm or sandstorm is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Visibility must be reduced to less than 1km to be counted as a duststorm or sandstorm. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>';
    infoFog: string = '<span class="text-sm w-full block">The risk for the selected period from fog reports by the weather station. If at any point in the day fog is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. Visibility must be reduced to less than 1km for fog to be reported, and this includes instances of freezing fog, mist, and haze if visibility is sufficiently reduced. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page)</span>'
    infoStorm: string = '<span class="text-sm w-full block">The risk for the selected period from thunderstorm reports by the weather station. if at any point in the day a thunderstorm is reported, it will count as a hit. Multiple observations in a single day will count as a single hit for the risk calculations. A thunderstorm report is given if thunder is heard at the station, whether lightning or precipitation are present or not. All Data and Since 2011 options are available in the Standard Model. The Advanced model provides the risk with greater influence from similar years (more information found in the MetSwift Analytics page) Typically, we advise a 1/10 ratio to account for locality. </span>'
    selectedTypes: any = {};
    selectedDates: boolean = false;
    hasTypeSelected: number = 0;
    hasTypeSelectedEdit: number=0;
    numberInsights: number = 0;
    datesInsights: number = 0;
    locationName: string = '';
    datesName: string = '';
    countryName:string='';
    countryCode:string='';
    state:any;
    postcode:string='';
    lati:any;
    longi:any;
    delphiRec:number=1;
    startDate:any;
    endDate:any;
    username:any;
    place:any;
    weatherData:any;
    weatherArray=[];
    riskArray=[];
    stDate:any;
    endDt:any;
    selectedWeatherTypes=[];
    insightCount:number=0;
    weatherArr:any=[];
    weatherExistsStr:any=[];
    riskDataNotFoundMessage:any;
    fetchedLocationData: any;
    dropdownList=[];
    overlayFlag: boolean = false;
    dataErrorMessage:any;
    showWeathers: boolean = false;
    public countryWiseHurricaneName:any;
    hurricaneName:any;
    hurricaneCategories:any;
    riskValues:any;
    eventData:any;
    getCStationAPIErrorCode:any;
    eventDetails;
    eventId;
    oldselectedTypes;
    selectedTypesEdit={};
    selectedWeatherCount: number = 0;
    selectedRiskCount: number = 0;
    riskDatesInsights: number = 0;
    maxInsightAllowed:number;
    riskExposureArray=[];
    riskclasess;
    profileData;
    unlimited:number = 1;
    prev_weather = ""
    prev_incomplete = {}
    station_data = {}
    total_days = 0
    incompletew = false
    missingw = false
    missing_w_arr = []
    incomplt_w_arr = []
    weather_labels = {'rain':'Rainfall', 'wind':'Wind', 'temperature':'Temperature', 'snow': 'Snow Depth', 'hail': 'Hail', 'dust': 'Dust', 'fog': 'Fog', 'storm': 'Storm'}
    missingwedit = ''
    missingarrdis= []
    edit_previous_checked = []
  constructor(
    private userService: UserService,
    private titleService: Title,
    private router: Router,
    private store: Store<AppState>,
    private newenquiryService: NewEnquiryService,
    private loader: LoaderService,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private elementRef: ElementRef
  ) {
    this.fetchProfile();
    this.route.params.pipe().subscribe(params => {
      this.eventId = +params["eventId"];
    });
    this.getState = this.store.select(selectAuthState)
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.searchState = navigation.extras.state.searchResults ? navigation.extras.state.searchResults : null;
      this.selectedTypes = navigation.extras.state.selectedTypes ? navigation.extras.state.selectedTypes : {};
      this.selectedTypesEdit = {};
      if (this.selectedTypes) {
        this.hasTypeSelected = Object.keys(this.selectedTypes).length
      }
    }

    this.titleService.setTitle('Edit Report - MetSwift');
  }
    ngOnInit(): void{
        this.getState.subscribe((state) => {
            this.username = state.user.username;
            this.unlimited = state.user.unlimited;
        });
        this.getRiskJSON();
        this.fetchEventData();
    }

    typeSelected(e){
        console.log(e);
        if(this.selectedTypes[e.type]){
            delete this.selectedTypes[e.type]
            this.numberInsights -= 1
        } else {
            this.selectedTypes[e.type] = true
            this.numberInsights += 1
        }
        if(this.selectedTypesEdit[e.type]){
            delete this.selectedTypesEdit[e.type]
        } else {
            this.selectedTypesEdit[e.type] = true
        }
        this.hasTypeSelected = Object.keys(this.selectedTypes).length;
        this.hasTypeSelectedEdit = Object.keys(this.selectedTypesEdit).length;
        this.selectedWeatherTypes = Object.keys(this.selectedTypes);
        this.selectedWeatherCount = this.getTypeSelectedLength('weather');
        this.selectedRiskCount = this.getTypeSelectedLength('risk');
        let weatherInsightCount:number = this.selectedWeatherCount*this.datesInsights;
        let riskInsightCount:number = this.selectedRiskCount*this.riskDatesInsights;
        this.insightCount = weatherInsightCount+riskInsightCount;
        // this.insightCount = this.hasTypeSelected*this.datesInsights;
    }

    getTypeSelectedLength(type) {
      let typeCount:number=0;
      let selectedTypes = Object.keys(this.selectedTypes);
      let len = selectedTypes.length;
      for ( let i=0;i<len;i++ ) {
        if ( type == 'risk' ) {
          if ( selectedTypes[i] == 'earthquake'  || selectedTypes[i] == 'tornado' || selectedTypes[i] == 'hurricane' || selectedTypes[i] == 'wildfire' ) {
            typeCount++;
          }
        } else {
          if ( selectedTypes[i] == 'rain'  || selectedTypes[i] == 'wind' || selectedTypes[i] == 'temperature' || selectedTypes[i] == 'snow' || selectedTypes[i] == 'hail' || selectedTypes[i] == 'dust' || selectedTypes[i] == 'fog' || selectedTypes[i] == 'storm' ) {
            typeCount++;
          }
        }
      }
      return typeCount;
    }

    calculateInsightsDates(){
        this.hasTypeSelected = Object.keys(this.oldselectedTypes).length;
        this.oldselectedTypes.forEach(e => {
            this.selectedTypes[e] = true
        });
        this.selectedWeatherTypes = this.oldselectedTypes;
        const a = moment(this.eventDetails.endDate, 'DD/MM/YYYY')
        const b = moment(this.eventDetails.startDate, 'DD/MM/YYYY')
        let days = a.diff(b, 'days') +1;
        this.total_days = days
        this.selectedDates = true;
        this.datesInsights = Math.ceil(days/31);
        this.riskDatesInsights = Math.ceil(days/7);
        // this.numberInsights = Math.ceil(days/31) + this.hasTypeSelected;
        // this.insightCount = this.hasTypeSelected*this.datesInsights;
    }

    changeSearchStatus(){
        if(this.eventDetails.startDate && this.eventDetails.endDate){
            this.datesName = '<span class="font-medium text-sm">' + this.eventDetails.startDate + '</span> to <span class="font-medium text-sm">' + this.eventDetails.endDate + '</span>'
        }

        this.extractLocationData();
    }



    extractLocationData() {
        this.getHurricaneName();
        if ( this.eventDetails.startDate != undefined  && this.eventDetails.endDate != undefined && this.eventDetails.locationName != undefined ) {
            this.loader.display(true);
            let requestData = JSON.stringify({
                country: this.eventDetails.country,
                postCode: this.eventDetails.postcode,
                username: this.username,
                state: this.eventDetails.state,
                countryName: this.eventDetails.countryName,
                uri: '/stats_data/get_c_station',
                lat: this.eventDetails.latitude,
                lon: this.eventDetails.longitude,
                isRegion: this.eventDetails.isRegion,
                regionId: this.eventDetails.regionId,
                delphiRec: this.delphiRec,
                Start_Date: this.eventDetails.startDate,
                End_Date: this.eventDetails.endDate,
                categories: this.hurricaneCategories,
                weathers: ""
            });
            this.newenquiryService.getCStation(requestData).subscribe((data)=> {
                this.loader.display(false);
                let successData = data;
                this.riskValues = data.riskValue;
                this.makeWeatherArray(data.keys);
                this.riskArray = this.getRiskArray(data.Risk);
                this.riskExposureArray = this.getRiskArray(data.Risk_exposure);
                this.riskclasess = data.riskCategories;
                // Change the risk message if there was exposure in past
                if ( !this.riskArray.includes('earthquake') && this.riskExposureArray.includes('earthquake') ) {
                  this.infoEarthquake = "No risk for Earthquake detected in your search window, however, this location is at risk of Earthquake during the year.";
                }
                if ( !this.riskArray.includes('tornadoes') && this.riskExposureArray.includes('tornadoes') ) {
                  this.infoTornadoes = "No risk for Tornado detected in your search window, however, this location is at risk of Tornado during the year.";
                }
                if ( !this.riskArray.includes('hurricanes') && this.riskExposureArray.includes('hurricanes') ) {
                  this.infoHurricane = "No risk for "+this.hurricaneName+" detected in your search window, however, this location is at risk of "+this.hurricaneName+" during the year.";
                } if ( !this.riskArray.includes('wildfire') && this.riskExposureArray.includes('wildfire') ) {
                  this.infoWildfire = "No risk for Wildfire detected in your search window, however, this location is at risk of Wildfire during the year.";
                }
             
                  this.fetchedLocationData = successData;
                //   s
                  this.dropdownList = this.fetchedLocationData.keys;
                  let len = this.dropdownList.length;
                  let weatherExistsArr:any=[];
                  for ( let we=0;we < len;we++ ) {
                    // if ( this.dropdownList[we].itemName == 'rain' || this.dropdownList[we].itemName == 'wind' || this.dropdownList[we].itemName == 'temperature' ) {
                    if ( this.dropdownList[we].itemName != 'snow' ) {
                      weatherExistsArr.push(this.dropdownList[we].itemName);
                    }
                  }
                  let weaLen = weatherExistsArr.length;
                  for ( let wea=0;wea<weaLen;wea++ ) {
                    if ( wea == 0 ) {
                      this.weatherExistsStr = '<strong>' +this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                    } else if ( wea == weaLen-1 ) {
                      this.weatherExistsStr += ' and <strong>'+this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                    } else {
                      this.weatherExistsStr += ', <strong>'+this.capitalizeWords(weatherExistsArr[wea])+'</strong>';
                    }
                  }
            },

            (error) => {
                error = JSON.parse(JSON.stringify(error));
                this.loader.display(false);
                this.overlayFlag = true;
                this.getCStationAPIErrorCode = error.status;
                this.showWeathers = false;
            }


            );
        }
    }

    removeSelection(){
        this.weatherArray = [];
        this.riskArray = [];
        this.startDate = '';
        this.endDate = '';
        this.datesName ='';
        this.datesInsights =0;
        this.selectedWeatherTypes = [];
        this.hasTypeSelected =0;
        this.selectedTypes = {}
        this.insightCount = 0;
        this.numberInsights = 0;
        this.stDate = '';
        this.endDt = '';
        this.prev_incomplete = {}
        this.prev_weather = ''
        this.station_data = {}
    }

    onClickBuyInsights(){
      this.ngxSmartModalService.getModal('reportLimit').close();
        this.loader.display(true);
        let selectedWeatherTypesEdit = Object.keys(this.selectedTypesEdit).toString();
        let checkGapBody = JSON.stringify({
          lat: this.eventDetails.latitude,
          lon: this.eventDetails.longitude,
          postCode: this.eventDetails.postcode,
          isRegion: this.eventDetails.isRegion,
          regionId: this.eventDetails.regionId,
          country: this.eventDetails.country,
          username: this.username,
          state: this.eventDetails.state,
          countryName: this.eventDetails.countryName,
          startDate: moment(this.eventDetails.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          endDate: moment(this.eventDetails.endDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          delphiRec: 1,
          fsi: 0,
          earthquakes: ( this.riskArray.includes('earthquake') ) ? 1 : 0,
          hurricanes: ( this.riskArray.includes('hurricanes') ) ? 1 : 0,
          tornadoes: ( this.riskArray.includes('tornadoes') ) ? 1 : 0,
          volcanoes: ( this.riskArray.includes('volcanoes') ) ? 1 : 0,
          wildfire: ( this.riskArray.includes('wildfire') ) ? 1 : 0,
          eventName: '',
          locationName: this.eventDetails.locationName,
          eventId: -1,
          groupId: -1,
          groupName: null,
          selectedTypes: selectedWeatherTypesEdit,
          totalInsights: this.insightCount,
          weathers: selectedWeatherTypesEdit,
          prev_weathers: this.prev_weather,
          prev_incomplete: this.prev_incomplete,
          station_data: this.station_data,
          edit_previous_checked: this.edit_previous_checked
      });

      this.newenquiryService.checkgapdata(checkGapBody).subscribe((data)=> {
        this.prev_incomplete = data.prev_incomplete
        this.prev_weather = data.prev_weathers.join(',')
        this.station_data = data.station_data
        this.prepareTheWeatherDataForTheEvent(data.station_data);
        for(let val in data.incomplete_weathers){
          if(data.incomplete_weathers[val] == 0){
              const index = this.weatherArray.indexOf(val);
              if (index > -1) {
                this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days)
                this.weatherArray.splice(index, 1);
              }
              this.missingw = true
              this.missing_w_arr.push(this.weather_labels[val])
          } else if(data.incomplete_weathers[val] < this.total_days){
            this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days)
            this.incompletew = true
            this.incomplt_w_arr.push(this.weather_labels[val])
          } else{
            this.gapsdatacheck(val, data.incomplete_weathers[val], this.total_days,true)
          }
        }
        let editEventBody = JSON.stringify({
            eventId: this.eventId,
            selectedTypes: selectedWeatherTypesEdit,
            totalInsights: this.insightCount,
            weathers: this.weatherData,
            startDate: moment(this.eventDetails.startDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            endDate: moment(this.eventDetails.endDate, 'DD/MM/YYYY').format('YYYY/MM/DD'),
            missingweathers: this.prev_incomplete
        });
        if(data.event_save == 1){
            this.newenquiryService.editEvent(editEventBody).subscribe((data)=> {

                this.eventData = data;
                let getModelDetailsData = JSON.stringify({
                    username: this.username,
                    eventId: this.eventId,
                    startDate: moment(this.eventDetails.startDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                    endDate: moment(this.eventDetails.endDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                    hurriCategory: '',
                    weathers: this.weatherData,
                    selectedWeatherTypes: selectedWeatherTypesEdit,
                    numberInsights: (this.insightCount-this.eventDetails.totalInsight),
                    hurricanename:'hurricanes'
                });
                console.log(getModelDetailsData);
                this.newenquiryService.getModels(getModelDetailsData).subscribe((modelsData)=> {
                    this.router.navigate(['/report/'+this.eventId], { state: {
                        selectedTypes: this.selectedTypes,
                        //searchState: this.searchState,
                        numberInsights: this.insightCount,
                        eventId: this.eventId
                    } });
                },
                (error) => {
                    this.loader.display(false);
                });
            },
          error => {
            this.loader.display(false);
            })
        } else {
          this.loader.display(false);
        }
      },
      error => {
        this.loader.display(false);
      })
      
    }

    gapsdatacheck(type, avaidays, totaldays, edit=false){
      let rain = <HTMLElement>this.elementRef.nativeElement.querySelector(`.button-type-weather--${type}`);
      let htmldata = document.createElement('div')

      htmldata.classList.add("text", "flex", "items-center", "justify-center", "gap-font")
      htmldata.textContent = avaidays +' of '+ totaldays +' days available'

      htmldata.style.display = "flex"
      htmldata.style.justifyContent = "centre"
      htmldata.style.color = "#ffffff" 
      htmldata.style.alignItems = "center"
      //htmldata.style.fontSize = "0.8rem"
      if(!edit){
        rain.click();
      }
      rain.firstElementChild.firstElementChild.appendChild(htmldata)
      let divlist = this.elementRef.nativeElement.querySelectorAll(`app-button-type .button-type-weather`)
      for (const box of divlist) {
        box.classList.add('gapdata');
      }
    }

    makeWeatherArray(weatherKeys) {
        let len = weatherKeys.length;
        if ( len > 0 && this.weatherArray.length < 1 ) {
            for ( let i=0;i<len;i++) {
                this.weatherArray.push(weatherKeys[i].itemName);
            }
        }
        if(this.missingwedit){
          setTimeout(()=>{
            if(this.missingwedit){
              this.missingwedit.split(',').forEach(val => {
                let gapno = parseInt(val.split('-')[1])
                let wname = val.split('-')[0]
                this.prev_weather += wname+','
                this.prev_incomplete[wname] = gapno
                this.edit_previous_checked.push(wname)
                if(gapno == 0 ){
                  // this.missing_w_arr.push(this.weather_labels[wname])
                  // this.missingw = true
                  this.missingarrdis.push(wname)
                } else if(gapno < this.total_days){
                  // this.incomplt_w_arr.push(this.weather_labels[wname])
                  // this.incompletew = true
                }
              });
              this.prev_weather = this.prev_weather.slice(0,this.prev_weather.length-1)
              console.log(this.prev_weather)
            }
            this.missingwedit.split(',').forEach(val => {
              this.gapsdatacheck(val.split('-')[0], val.split('-')[1], this.total_days,true)
            });
            }, 100, true)

        }
    }

    getRiskArray(riskObject) {
        let availblRiskArr = [];
        Object.keys(riskObject).forEach(function(key){
            let value = riskObject[key];
            if ( value == 1 ) {
                availblRiskArr.push(key);
            }
        });
        return availblRiskArr;
    }

    prepareTheWeatherDataForTheEvent(weather) {
      let insightWeatherStations = [];
      let i=0
      for (let x in weather) {
          insightWeatherStations[i] = {};
          insightWeatherStations[i]["itemName"] = x;
          insightWeatherStations[i]["stationId"] = weather[x]["best"]["stationId"];
          insightWeatherStations[i]["stationLat"] = weather[x]["best"]["stationData"]["Lat"];
          insightWeatherStations[i]["stationLon"] = weather[x]["best"]["stationData"]["Lon"];
          insightWeatherStations[i]["stationName"] = weather[x]["best"]["stationData"]["StationName"];
          insightWeatherStations[i]["isRecommended"] = 1;
          i++
      }
      this.weatherData = insightWeatherStations;
    }

    locationChanged(e) {
        this.selectedTypes = {}
        this.insightCount = 0;
        this.locationName = '';
        this.datesName = '';
        this.datesInsights = 0;
        this.numberInsights = 0;
        this.weatherArray = [];
        this.riskArray = [];
        this.startDate = '';
        this.endDate = '';
        this.stDate = '';
        this.endDt = '';
        this.selectedWeatherTypes = [];
        this.hasTypeSelected = 0;
        this.prev_incomplete = {}
        this.prev_weather = ''
        this.station_data = {}
    }

    capitalizeWords(str) {
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    }

    closeOverlay() {
        this.riskDataNotFoundMessage = false;
        this.overlayFlag = false;
        this.weatherExistsStr = [];
        this.weatherArr = [];
        this.dataErrorMessage = '';
    }

    async getRiskJSON() {
        let res = await this.newenquiryService.getJSON();
        let strData = JSON.stringify(res);
        this.countryWiseHurricaneName = JSON.parse(strData);
    }

    getHurricaneName() {
        let len = this.countryWiseHurricaneName.length;
        this.hurricaneName = 'Hurricanes';
        for ( let i=0;i<len;i++ ) {
          if ( this.countryWiseHurricaneName[i].code == this.eventDetails.country ) {
            this.hurricaneName = this.countryWiseHurricaneName[i].title;
            this.hurricaneCategories = this.countryWiseHurricaneName[i].category.split(',');
          }
        }
    }

    fetchEventData() {
        this.loader.display(true);
        let data = JSON.stringify({
          eventId: this.eventId,
          username: this.username
        });
        this.newenquiryService
          .getEventData(data)
          .pipe()
          .subscribe(
            data => {
              this.loader.display(false);
              let dd = JSON.parse(JSON.stringify(data));
              let successData = dd;
              this.eventDetails = successData.data.event;
              this.stDate  = moment(this.eventDetails.startDate, 'DD/MM/YYYY').format('MMM DD')
              this.endDt   = moment(this.eventDetails.endDate, 'DD/MM/YYYY').format('MMM DD')
              this.title = this.eventDetails.locationName;
              this.missingwedit = this.eventDetails.missingweathers
              this.changeSearchStatus();
              this.oldselectedTypes = this.getSelectedTypesInTheEvent(successData.data);
              this.calculateInsightsDates();
              this.insightCount = this.eventDetails.totalInsight;
              
              //this.onClickTypeWeather(this.selectedTypes[0]);
              // this.getEvents();
              // this.getModelsData();
            },
            error => {
              this.loader.display(false);
            });
      }

      getSelectedTypesInTheEvent(data) {
        let availableItems = [];
        if ( data != '' ) {
          Object.keys(data).forEach(function(key){
              if ( key != 'event' && key != 'risk' && key != 'isRiskModelPurchased' )
                availableItems.push(key);
          });
          if ( data.risk != null ) {
            Object.keys(data.risk).forEach(function(key){
                if ( data.risk[key].risk_name != 'volcanoes' )
                  availableItems.push(data.risk[key].risk_name);
            });
          }
        }
        return availableItems;
      }

      gotoreportpage(){
        this.loader.display(true);
        let selectedWeatherTypesEdit = Object.keys(this.selectedTypesEdit).toString();
        let checkGapBody = JSON.stringify({
          lat: this.eventDetails.latitude,
          lon: this.eventDetails.longitude,
          postCode: this.eventDetails.postcode,
          country: this.eventDetails.country,
          username: this.username,
          state: this.eventDetails.state,
          countryName: this.eventDetails.countryName,
          startDate: moment(this.eventDetails.startDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          endDate: moment(this.eventDetails.endDate, 'DD/MM/YYYY').format('DD/MM/YYYY'),
          delphiRec: 1,
          fsi: 0,
          earthquakes: ( this.riskArray.includes('earthquake') ) ? 1 : 0,
          hurricanes: ( this.riskArray.includes('hurricanes') ) ? 1 : 0,
          tornadoes: ( this.riskArray.includes('tornadoes') ) ? 1 : 0,
          volcanoes: ( this.riskArray.includes('volcanoes') ) ? 1 : 0,
          wildfire: ( this.riskArray.includes('wildfire') ) ? 1 : 0,
          eventName: '',
          locationName: this.eventDetails.locationName,
          eventId: this.eventId,
          groupId: -1,
          groupName: null,
          selectedTypes: selectedWeatherTypesEdit,
          totalInsights: this.insightCount,
          weathers: selectedWeatherTypesEdit,
          prev_weathers: this.prev_weather,
          prev_incomplete: this.prev_incomplete,
          station_data: this.station_data,
          edit_previous_checked: this.edit_previous_checked
      });
      this.newenquiryService.checkgapdata(checkGapBody).subscribe((data)=> {
        this.loader.display(false);
      }, error=> {
        this.loader.display(false);
      })
      this.router.navigate(['/report/'+this.eventId], { state: {
        selectedTypes: this.selectedTypes,
        numberInsights: this.insightCount,
        eventId: this.eventId
    } });
      }

      checkIfInsightCountIsUnderLimit() {
        this.maxInsightAllowed = Number(this.profileData.insightThreshold);
        if ( this.insightCount > this.maxInsightAllowed ) {
          if(this.unlimited != 1){
            this.ngxSmartModalService.getModal('reportLimit').open();
          } else{
            this.onClickBuyInsights();
          }
        } else {
          this.onClickBuyInsights();
        }
      }

      closePopup() {
        this.ngxSmartModalService.getModal('reportLimit').close();
        return;
      }

      fetchProfile() {
        this.loader.display(true);
        this.userService.fetchuserProfile({})
          .subscribe(
            data => {
              this.loader.display(false);
              let successData = data;
              this.profileData = successData.profile;
            },
            error => {
              let errordata = error;
            });

    }
    

    ngAfterViewInit(){
      
    }

}
