import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV } from './../app.config';

@Injectable({
  providedIn: 'root'
})
export class NewEnquiryService {
    constructor(
        private http: HttpClient
      ) { }

  getCStation(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_c_station`, data);
  }
  
  getCStationLite(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_model_info`, data);
  }
  
  getMapRiskData(data:Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_map_risk_data`, data);
  }

  addNewEvent(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/addevent`, data);
  }

  checkgapdata(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/stats_data/get_gap_data_weathers`, data);
  }
  

  getModels(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/getmodels`, data);
  }

  async getJSON(): Promise<JSON> {
    const response = await this.http.get<any>("./assets/csvjson.json").toPromise();
    let data = response;
    return data;
  }

  getGraph(body: Object): Observable<any> {
    return this.http.post<any>(ENV.BASE_API + '/api/insight/getgraphdata', body);
  }

  fetchgraphdata(body): Observable<any> {
    Object.defineProperty(body, "uri", {
        value: '/stats_data/get_graph_data',
        writable: true,
        enumerable: true,
        configurable: true
    });
    return this.http.post<any>(ENV.WEATHER_API + '/stats_data/get_graph_data', body);
  }

  downloadGraphAndOverviewPdf(body: Object){
    body = JSON.stringify(body);
    let HTTPOptions:Object = {
      responseType: 'text'
  }
    return this.http.post<any>(ENV.BASE_API + '/api/insight/downloadGraphPdf', body, HTTPOptions);
  }

  getEventData(data: Object): Observable<any> {
    return this.http.post(`${ENV.BASE_API}/api/insight/geteventdata`, data);
  }

  editEvent(data: Object): Observable<any> {
    return this.http.post(`${ENV.WEATHER_API}/insight/editevent`, data);
  }

  saveXLSXFileBase64IntoDB(body: Object): Observable<any> {
    return this.http.post<any>(ENV.BASE_API + '/api/insight/saveXLSXStringIntoDB', body);
  }

  lastFiveYearDataGet(body: Object): Observable<any> {
    Object.defineProperty(body, "uri", {
        value: '/risk_data/get_risk_historical',
        writable: true,
        enumerable: true,
        configurable: true
    });
    body = JSON.stringify(body);
    return this.http.post<any>(ENV.WEATHER_API + '/risk_data/get_risk_historical', body);
  }

  mailOnErr(body: Object): Observable<any> {
    body = JSON.stringify(body);
    return this.http.post<any>(ENV.BASE_API + '/api/insight/error', body);
  }

  getRegions(body: Object): Observable<any> {
    return this.http.post<any>(ENV.BASE_API + '/api/insight/getregions', body);
  }

  getRegionGeojson(body: Object): Observable<any> {
    return this.http.post<any>(ENV.BASE_API + '/api/insight/getregionGeojson', body);
  }

}