<div id="map-container" [ngClass]="{ 'cursor-pin-select': isPinSelect }" class="w-full h-64 rounded-md"></div>

<!-- Custom Control Group -->
<div id="custom_mapbox_ctrl_group_select" class="mapboxgl-ctrl mapboxgl-ctrl-group">
  <button (click)="toggleMapSelect($event, 'pin')" class="custom-control-button select-type pin-select">
    <img *ngIf="isPinSelect" src="/assets/images/map-pin-select-white.png" />
    <img *ngIf="!isPinSelect" src="/assets/images/map-pin-select-primary.png" />
  </button>
  <button (click)="toggleMapSelect($event, 'polygon')" class="relative custom-control-button select-type polygon-select">
    <div *ngIf="geojson_loading" class="spinner-container">
      <div class="spinner"></div>
    </div>
    <img *ngIf="isPolygonSelect && !geojson_loading" src="/assets/images/map-polygon-select-white.png" />
    <img *ngIf="!isPolygonSelect && !geojson_loading" src="/assets/images/map-polygon-select-primary.png" />
  </button>
</div>

<div id="custom_mapbox_ctrl_group_region_types" class="mapboxgl-ctrl mapboxgl-ctrl-group">
  <div *ngIf="isPolygonSelect">
    <button (click)="toggleRegionType($event, 'A0')" class="custom-control-button region-type active">
      <span>A0</span>
    </button>
    <button (click)="toggleRegionType($event, 'A1')" class="custom-control-button region-type">
      <span>A1</span>
    </button>
    <button (click)="toggleRegionType($event, 'A2')" class="custom-control-button region-type">
      <span>A2</span>
    </button>
  </div>
</div>
