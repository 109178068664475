import { Component, OnInit, EventEmitter, Input, Output, AfterViewInit, SimpleChanges, Renderer2, ElementRef  } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';

interface GeoJSONRegionItem {
  id: string;
  name: string;
  geojson: {
    type: 'FeatureCollection';
    features: Array<{
      type: 'Feature';
      geometry: GeoJSON.Geometry;
    }>;
  };
}

@Component({
  selector: 'app-newreport-map',
  templateUrl: './newreport-map.component.html',
  styleUrls: ['./newreport-map.component.scss']
})
export class NewreportMapComponent implements OnInit, AfterViewInit {

  map!: mapboxgl.Map;
  markers: mapboxgl.Marker[] = [];
  isPinSelect: boolean = false;
  isPolygonSelect: boolean = false;
  regionType:  string | null = null;
  geojson_loading: boolean = false;
  @Input() regionListWithGeojsonArr: GeoJSONRegionItem[] = [];
  @Input() mapLocation: [number, number];
  @Input() mapGeojson: any;
  @Input() mapPin: boolean;
  @Output() mapPinDropped = new EventEmitter<[number, number]>();
  @Output() mapRegionSelect: EventEmitter<any> = new EventEmitter();

  private readonly mapStyleBase ='mapbox://styles/metswift-maps/cm3508iws00nm01qz7w1ddw6f';

  constructor(private renderer: Renderer2, private elRef: ElementRef) { }

  ngOnInit(): void {
    (mapboxgl as any).accessToken = 'pk.eyJ1IjoibWV0c3dpZnQtbWFwcyIsImEiOiJjbTJ1OTg5N3EwMTYwMmpwZ2I3cXB5emF6In0.E-kj6fe-IJezELG6uuSfPQ';
  }

  ngAfterViewInit(): void {
    this.geojson_loading = true;
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: this.mapStyleBase,
      center: this.mapLocation,
      zoom: 2.8,
      minZoom: 1,
      maxZoom: 15
    });
    this.map.scrollZoom.setZoomRate(1 / 200);
    this.map.scrollZoom.setWheelZoomRate(1 / 200);
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    this.map.on('click', (e) => { this.mapClick(e); });
    const customControlGroupSelect = this.elRef.nativeElement.querySelector('#custom_mapbox_ctrl_group_select');
    const customControlGroupRegionTypes = this.elRef.nativeElement.querySelector('#custom_mapbox_ctrl_group_region_types');
    const mapControlsContainer = this.elRef.nativeElement.querySelector('.mapboxgl-ctrl-top-right');
    if (mapControlsContainer && customControlGroupSelect) {
      this.renderer.appendChild(mapControlsContainer, customControlGroupSelect);
    }   
    if (mapControlsContainer && customControlGroupRegionTypes) {
      this.renderer.appendChild(mapControlsContainer, customControlGroupRegionTypes);
    }   
    this.map.on('error', (e) => {
      console.error('Mapbox GL error:', e.error.message, e);
    });

    /// Temp for getting region bboxs where they are faulty
    /*this.map.on('moveend', () => {
      const bounds = this.map.getBounds(); // Get the current map bounds
      const west = bounds.getWest();
      const south = bounds.getSouth();
      const east = bounds.getEast();
      const north = bounds.getNorth();
      const bbox = [west, south, east, north];      
      console.log('Bounding Box:', bbox);
    });*/
    ///
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapLocation && !changes.mapLocation.firstChange) {
      this.mapLocation = changes.mapLocation.currentValue;
      this.onLocationChange();
    }
    if (changes.mapGeojson && !changes.mapGeojson.firstChange) {
      this.mapGeojson = changes.mapGeojson.currentValue;
      if (this.map.isStyleLoaded()) {
        this.onLocationChange();
      } else {
        this.map.once('styledata', () => {
          this.onLocationChange();
        });
      }
    }
    if (changes['regionListWithGeojsonArr'] && this.regionListWithGeojsonArr.length > 0) {
      this.geojson_loading = false;
    }
  }

  onLocationChange() {
      this.removeMarkers();
      this.removeLayersAndSources();
      if (this.mapPin) {
        this.addMarker(this.mapLocation);
      }
      if (this.mapGeojson && Object.keys(this.mapGeojson).length > 0) {
        if (!this.map.getSource('region-source')) {
          this.map.addSource('region-source', {
            type: 'geojson',
            data: this.mapGeojson
          });
        } else {
          const geoJsonSource = this.map.getSource('region-source') as mapboxgl.GeoJSONSource;
          if (geoJsonSource) {
            geoJsonSource.setData(this.mapGeojson);
          }
        }
        if (!this.map.getLayer('region-fill-layer')) {
          this.map.addLayer({
            id: 'region-fill-layer',
            type: 'fill',
            source: 'region-source',
            layout: {},
            paint: {
              'fill-color': '#F7A655',
              'fill-opacity': 0.26,
            }
          });
        }
        if (!this.map.getLayer('region-line-layer')) {
          this.map.addLayer({
            id: 'region-line-layer',
            type: 'line',
            source: 'region-source',
            layout: {},
            paint: {
              'line-color': '#F4800B',
              'line-width': 0.5
            }
          });
        }
        this.map.fitBounds(this.mapGeojson.bbox, {
          padding: 50,
          essential: true
        });
      } else {
        const zoom = this.mapPin ? Math.max(this.map.getZoom(), 4.8) : 3;
        this.map.flyTo({
          center: this.mapLocation,
          zoom: zoom,
          essential: true
        });
      }
      
      
  }

  mapClick(event) {
    if (this.isPinSelect) {
      let geocoder = new google.maps.Geocoder;
      const lat = event.lngLat.lat;
      const lng = event.lngLat.lng
      geocoder.geocode({ 'location': { lat, lng } }, (results, status) => {
        if (results.length > 1) {
          this.removeMarkers();
          this.removeLayersAndSources();      
          this.addMarker(event.lngLat);
          this.mapPin = true;
          this.mapLocation = [ event.lngLat.lng, event.lngLat.lat ];
          this.onLocationChange();
          this.mapPinDropped.emit(this.mapLocation);
        } else {
          this.showErrorIcon(event);
        }
      });
    } else if (this.isPolygonSelect) {
      const adminAreaTypeRegionArr = this.regionListWithGeojsonArr.filter(region => region.id.startsWith(this.regionType));
      const clickedPoint = turf.point([event.lngLat.lng, event.lngLat.lat]);
      adminAreaTypeRegionArr.find(region => {
        const firstFeature = region.geojson.features[0];
        if (firstFeature) {
          const geometry = firstFeature.geometry;
          if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
            if (turf.booleanPointInPolygon(clickedPoint, geometry)) {
              this.mapRegionSelect.emit(region);
            }
          }
        }
      });
    }
  }

  toggleMapSelect(event: Event, buttonSelectType: string): void {
    const button = (event.target as HTMLElement).closest('button');
    const currentlyActive = document.querySelector('#custom_mapbox_ctrl_group_select .custom-control-button.active');
    currentlyActive?.classList.remove('active');
    if (button) button.classList.add('active');
    this.isPinSelect = buttonSelectType === 'pin';
    this.isPolygonSelect = buttonSelectType === 'polygon';
    const mapCanvas = this.elRef.nativeElement.querySelector('.mapboxgl-canvas');
    if (mapCanvas) {
      if (this.isPinSelect) {
        this.renderer.setStyle(mapCanvas, 'cursor', 'crosshair');
      } else if (this.isPolygonSelect) {
        this.toggleRegionType(null, 'A0');
        this.renderer.setStyle(mapCanvas, 'cursor', 'pointer');
      } else {
        this.renderer.setStyle(mapCanvas, 'cursor', 'default');
      }
    }
    if (buttonSelectType === 'pin') {
      this.regionType = null;
    }

  }

  toggleRegionType(event: Event, buttonRegionType: string): void {
    if (event != null) {
      const button = (event.target as HTMLElement).closest('button');
      const currentlyActive = document.querySelector('#custom_mapbox_ctrl_group_region_types .custom-control-button.active');
      currentlyActive?.classList.remove('active');
      if (button) button.classList.add('active');
    }
    this.regionType = buttonRegionType;
    if (this.regionType === 'A0') {
      this.map.setPaintProperty('admin-0-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2.5 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 2, 0.2, 14, 2.2 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-color', '#706388');
      this.map.setPaintProperty('admin-2-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 2, 0.2, 22, 2 ]);
      this.map.setPaintProperty('admin-2-boundary', 'line-color', '#9ca09f');
    }
    if (this.regionType === 'A1') {
      this.map.setPaintProperty('admin-0-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-color', '#4c9770');
      this.map.setPaintProperty('admin-2-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 2, 0.2, 22, 2 ]);
      this.map.setPaintProperty('admin-2-boundary', 'line-color', '#9ca09f');
    }
    if (this.regionType === 'A2') {
      this.map.setPaintProperty('admin-0-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2 ]);
      this.map.setPaintProperty('admin-1-boundary', 'line-color', '#3ba358');
      this.map.setPaintProperty('admin-2-boundary', 'line-width', [ 'interpolate', ['linear'], ['zoom'], 0, 0.75, 8, 2 ]);
      this.map.setPaintProperty('admin-2-boundary', 'line-color', '#d9ad82');
    }
  }
  

  isPointInRegion(lat: number, lng: number, regionGeojson: any): boolean {
    const point = turf.point([lng, lat]);
    return turf.booleanPointInPolygon(point, regionGeojson);
  }

  addMarker(loc) {
    const marker = this.customMarker(loc);
    marker.addTo(this.map);
    this.markers.push(marker);
  }

  customMarker(coordinates: [number, number]): mapboxgl.Marker {
    const markerElement = document.createElement('div');
    markerElement.style.backgroundImage = `url(assets/images/map-search-pin.png)`;
    markerElement.style.backgroundSize = 'contain';
    markerElement.style.width = '32px';
    markerElement.style.height = '32px';
    markerElement.style.transform = 'translate(-50%, -50%)';
    return new mapboxgl.Marker(markerElement, { anchor: 'bottom' })
      .setLngLat(coordinates);
  }  

  removeMarkers(): void {
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  removeLayersAndSources(): void {
    if (this.map.getLayer('region-fill-layer')) {
      this.map.removeLayer('region-fill-layer');
    }
    if (this.map.getLayer('region-line-layer')) {
      this.map.removeLayer('region-line-layer');
    }
    if (this.map.getSource('region-source')) {
      this.map.removeSource('region-source');
    }
  }

  showErrorIcon(event: any) {
    const mapContainer = this.elRef.nativeElement.querySelector('#map-container');
    const mapCanvas = this.elRef.nativeElement.querySelector('.mapboxgl-canvas');
    const errorIcon = document.createElement('div');
    errorIcon.classList.add('error-cursor-waves');
    errorIcon.style.left = `${event.point.x + 2}px`;
    errorIcon.style.top = `${event.point.y - 18}px`;
    mapContainer.appendChild(errorIcon);
    const updateIconPosition = (event) => {
        const rect = mapCanvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        errorIcon.style.left = `${x + 2}px`;
        errorIcon.style.top = `${y - 18}px`;
    };
    mapCanvas.addEventListener('mousemove', updateIconPosition);
   mapCanvas.addEventListener('mouseleave', () => {
      if (mapContainer.contains(errorIcon)) {
        mapContainer.removeChild(errorIcon);
      }
    });
    setTimeout(() => {
      errorIcon.style.opacity = "0";
      setTimeout(() => {
          mapContainer.removeChild(errorIcon);
      }, 200);
    }, 300);
  }

}
