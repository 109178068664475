import { Component, OnInit } from '@angular/core';
import { LoaderService } from './../../../services/loader.service';
import { ConnectionService } from './../../../services/connection.service';
import { DashboardService } from './../../../services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { removeAllListeners } from 'process';

@Component({
  selector: 'app-latest-news-widget',
  templateUrl: './latest-news-widget.component.html',
  styleUrls: ['./latest-news-widget.component.scss']
})
export class LatestNewsWidgetComponent implements OnInit {
    news: any = [
        {
            category: '',
            thumbnail: null,
            title: '',
            alert: 'Gray'
        },
        {
            category: '',
            thumbnail: null,
            title: '',
            alert: 'Gray'
        },
        {
            category: '',
            thumbnail: null,
            title: '',
            alert: 'Gray'
        },
    ];
    destroy$: Subject<any> = new Subject();

    constructor(
        private loader:LoaderService,
        private connectionService:ConnectionService,
        private dashboardService:DashboardService
    ) { 

    }

    ngOnInit(): void {
        this.getNews();
    }

    getNews() {
        if (navigator.onLine == true) {
            this.loader.display(true);
            this.dashboardService.getNews({ "categoryId": ['EQ','TC','WF','DR','FL','VO'], "alert": ['Green','Orange', 'Red'], "pageNumber": '1', "pageSize": '3' })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                data => {
                    this.loader.display(false);
                    let successData = data;
                    if ( successData && successData.news ) {
                        let len = successData.news.length;
                        for ( let i = 0; i < len; i++ ) {
                            if (i < 3 && successData.news[i]) {
                                this.news[i].category = successData.news[i].category;
                                this.news[i].thumbnail = successData.news[i].mapUrl ?? successData.news[i].imageUrl;
                                this.news[i].defaultThumbnail = successData.news[i].defaultUrl ?? null;
                                this.news[i].title = successData.news[i].title;
                                this.news[i].description = successData.news[i].description;
                                this.news[i].severity = successData.news[i].severity;
                                this.news[i].severityValue = successData.news[i].severityValue;
                                this.news[i].severityUnit = successData.news[i].severityUnit;
                                this.news[i].statLine = successData.news[i].statLine;
                                let lat = Math.abs(successData.news[i].latitude).toFixed(3) + '°' + (successData.news[i].latitude >= 0 ? 'N' : 'S');
                                let long = Math.abs(successData.news[i].longitude).toFixed(3) + '°' + (successData.news[i].longitude >= 0 ? 'E' : 'W');
                                this.news[i].location = 'Location: ' + lat + ', ' + long;
                                this.news[i].toDate = new Date(successData.news[i].toDate).toLocaleString('en-US', { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', timeZone: 'UTC', hour12: false }) + '<b class="font-normal ml-1"> UTC</b>';
                                this.news[i].link = successData.news[i].link;
                                this.news[i].alert = successData.news[i].alertLevel;
                                this.news[i].isCurrent = successData.news[i].isCurrent;
                            }
                        }
                    }
                },
                error => {
                    this.loader.display(false);
                }
            )
        } else {
            this.connectionService.display(true);
            this.loader.display(false);
        }
    }

}
